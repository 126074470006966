import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  label: {
    textTransform: "capitalize",
  },
});

export default (props) => {
  const classes = useStyles();
  return (
    <TextField
      variant="outlined"
      size="small"
      {...props}
      InputLabelProps={{ classes: { root: classes.label } }}
    />
  );
};
