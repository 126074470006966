import React, { useEffect } from "react";
import { Paper, Grid, Typography, Box } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/react-hooks";
import * as yup from "yup";
import Button from "../../../../Components/MuiComponents/Button";
import AutoTab from "../../../../Components/FormikFields/AutoTabField";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    paddingTop: theme.spacing(10),
  },
}));

const getGroupCode = gql`
  query groupcode($registrationCode: RegistrationCodeInput) {
    checkRegistrationCode(registrationCode: $registrationCode) {
      response
      statusCode
      successStatus
    }
  }
`;

const tokenSchema = yup.object().shape({
  token: yup
    .number()
    .typeError("Enter 6 digit code")
    .required("Enter 6 digit code")
    .min(100000, "Enter 6 digit code")
    .max(999999, "Enter 6 digit code"),
});

export default ({
  dispatchSave,
  goNext,
  currentState,
  setStep,
  location: { state },
}) => {
  const classes = useStyles();
  useEffect(() => {
    setStep(1);
  }, []);
  const [checkRegCode, { loading, data, error }] = useLazyQuery(getGroupCode);
  let formPropsRef;
  const submitFunction = async (values) => {
    const registrationCode = {
      registrationCode: values.token,
    };
    checkRegCode({
      variables: {
        registrationCode,
      },
    });
  };
  useEffect(() => {
    if (state && formPropsRef) {
      formPropsRef.setFieldValue("token", state.initialToken);
      setTimeout(formPropsRef.submitForm, 200);
    }
  }, []);

  useEffect(() => {
    if (data && data.checkRegistrationCode.statusCode === 200) {
      dispatchSave({ type: "token", body: { ...formPropsRef.values } });
      goNext(1);
    }
  }, [data]);
  return (
    <Box className={classes.root}>
      <Formik
        initialValues={currentState}
        validationSchema={tokenSchema}
        validateOnBlur
        validateOnChange
        onSubmit={submitFunction}
      >
        {(props) => {
          formPropsRef = props;
          return (
            <>
              <Form>
                <Paper elevation={6} style={{ padding: "20px" }}>
                  <Grid
                    container
                    spacing={8}
                    direction="column"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                      <Typography variant="h4">REGISTRATION CODE</Typography>
                      <Typography variant="body2">
                        Please enter your QualityCare Connect Registration Code.
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{ textAlign: "center", minWidth: "100%" }}
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={1} md={2} />
                        <Grid item xs={12} md={8}>
                          <Field
                            component={AutoTab}
                            inputProps={{ maxLength: 6 }}
                            name="token"
                            label="registration token"
                          />
                        </Grid>
                        <Grid item xs={1} md={2} />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                      <Button
                        type="submit"
                        loading={loading}
                        disabled={loading}
                      >
                        Enter
                      </Button>
                      {error && (
                        <Typography variant="body1">
                          Unexpected Error. Please try again later.
                        </Typography>
                      )}
                      {data &&
                        data.checkRegistrationCode.statusCode !== 200 && (
                          <Typography variant="body1">
                            Registration Code is invalid
                          </Typography>
                        )}
                    </Grid>
                  </Grid>
                </Paper>
              </Form>
            </>
          );
        }}
      </Formik>
    </Box>
  );
};
