import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import StripeCheckout from "react-stripe-checkout";
import Button from "../../../Components/MuiComponents/Button";
import { useAxios } from "../../../context/axiosAuthenticated";
import InfoDialog from "../../../Components/MuiComponents/InfoDialog";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const UpgradePaymentButton = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { axiosInstance } = useAxios();
  const publishableKey = props.stripeKey || "pk_test_4iVn9sFDg6y7d0G3Os7RxoOs";
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const [paymentConfirm, setPaymentConfirm] = React.useState(false);
  const [updateCardAlertText, setUpdateCardAlertText] = useState(null);

  const onToken = async (token) => {
    setBackdropOpen(true);
    if (token && token.id) {
      let body = {
        token: token.id,
      };
      await axiosInstance.post("/buyupapiauth/updateCustomerCardDetails", body);
      setBackdropOpen(false);
      setUpdateCardAlertText("Card updated successfully");
      setPaymentConfirm(true);
    } else {
      setBackdropOpen(false);
      setUpdateCardAlertText("Failed to update card");
      setPaymentConfirm(true);
    }
  };

  function onOkay() {
    history.go(0);
  }

  return (
    <>
      <StripeCheckout
        email={props.email}
        panelLabel="Update Card"
        name="ArmadaHealth"
        token={onToken}
        stripeKey={publishableKey}
        image="https://s3.amazonaws.com/armadahealth.com/ah-logo.png"
        billingAddress={false}
      >
        <Button color="primary" variant="outlined">
          Update Card
        </Button>
      </StripeCheckout>
      <Backdrop
        className={classes.backdrop}
        open={backdropOpen}
        onClick={() => {
          setBackdropOpen(false);
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <InfoDialog
        description={updateCardAlertText}
        onOkay={() => {
          setPaymentConfirm(false);
          onOkay();
        }}
        open={paymentConfirm}
      />
    </>
  );
};

export default UpgradePaymentButton;
