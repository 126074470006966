import React, { useEffect, useState } from "react";
import StripeCheckout from "react-stripe-checkout";
import { useHistory } from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { useAxios } from "../../../context/axiosAuthenticated";
import Button from "../../../Components/MuiComponents/Button";
import InfoDialog from "../../../Components/MuiComponents/InfoDialog";
import { useCurrentUser } from "../../../context/CurrentUser";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const BuyUpPaymentButton = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { currentUser } = useCurrentUser();
  const { axiosInstance } = useAxios();
  const publishableKey = props.stripeKey || "pk_test_4iVn9sFDg6y7d0G3Os7RxoOs";
  const [priceResponse, setPriceResponse] = useState(null);
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const [, setError] = useState(null);

  const [buyupAlert, setBuyupAlert] = useState(false);
  const [buyupAlertText, setBuyupAlertText] = useState(null);

  useEffect(() => {
    const fetchPlanPrice = async () => {
      let { data: prResponse } = await axiosInstance.get(
        `/buyupapiauth/getPriceForPlan/${props.plan.id}`
      );
      if (prResponse && prResponse.price && prResponse.price > 0) {
        setPriceResponse(prResponse);
      } else {
        setError("Failed to fetch plan price");
      }
    };
    fetchPlanPrice();
  }, []);

  const onToken = async (token) => {
    setBackdropOpen(true);
    if (token && token.id) {
      let body = {
        token: token.id,
        buyUpId: props.plan.id,
        timeZoneOffset: new Date().getTimezoneOffset(),
      };
      let { data: purchaseResponse } = await axiosInstance.post(
        "/buyupapiauth/processUserPaymentForBuyUp",
        body
      );
      if (
        purchaseResponse &&
        purchaseResponse.buyupIdBought &&
        purchaseResponse.buyUpExpiryDate
      ) {
        setBackdropOpen(false);
        setBuyupAlertText("Plan bought sucessfully");
        setBuyupAlert(true);
      } else {
        setBackdropOpen(false);
        setBuyupAlertText("Unable to process the payment");
        setBuyupAlert(true);
      }
    } else {
      setBackdropOpen(false);
      setBuyupAlertText("Unable to process the payment");
      setBuyupAlert(true);
    }
  };

  function onOkay() {
    history.push(currentUser.shouldShowWelcome ? "./welcome" : "./");
    history.go();
  }

  return (
    <>
      {priceResponse && priceResponse.price > 0 ? (
        <StripeCheckout
          email={props.email}
          name="ArmadaHealth"
          amount={priceResponse.price} // Amount in cents
          token={onToken}
          stripeKey={publishableKey}
          image="https://s3.amazonaws.com/armadahealth.com/ah-logo.png"
          billingAddress={false}
        >
          <Button>{`Upgrade for $${(priceResponse.price / 100).toFixed(
            2
          )}`}</Button>
        </StripeCheckout>
      ) : (
        <Button disabled>Loading Price</Button>
      )}
      <Backdrop
        className={classes.backdrop}
        open={backdropOpen}
        onClick={() => {
          setBackdropOpen(false);
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <InfoDialog
        open={buyupAlert}
        description={buyupAlertText}
        onOkay={() => {
          setBuyupAlert(false);
          onOkay();
        }}
      />
    </>
  );
};

export default BuyUpPaymentButton;
