import React, { useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Redirect } from "react-router-dom";
import { useCurrentUser } from "../../context/CurrentUser";

const logoutQuery = gql`
  mutation logout($user: userDetailsInput) {
    logoutUser(user: $user) {
      response
      statusCode
    }
  }
`;

export default () => {
  const [logout, { data, error }] = useMutation(logoutQuery);
  const { deleteCurrentUser } = useCurrentUser();

  useEffect(() => {
    deleteCurrentUser();
    logout();
  }, []);
  return data || error ? <CircularProgress /> : <Redirect to="/" />;
};
