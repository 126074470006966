import React from "react";
import { FormHelperText } from "@material-ui/core";
import { getIn } from "formik";
import TextField from "../MuiComponents/TextField";

const normalizeInput = (value, previousValue) => {
  // return nothing if no value
  if (!value) return value;

  // only allows 0-9 inputs
  const currentValue = value.replace(/[^\d]/g, "");
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {
    // returns: "x", "xx", "xxx"
    if (cvLength < 4) return currentValue;

    // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
    if (cvLength < 7)
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;

    // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)}`;
  }
  if (previousValue && previousValue.length > cvLength) {
    return value;
  }
  return undefined;
};

export default ({
  field,
  form,
  name,
  helperText,
  error,
  onChange,
  onBlur,
  displayErrorMessage = true,
  ...otherProps
}) => {
  let internalhelperText = helperText || "   ";
  const changeHandler = (event) => {
    form.setFieldValue(
      field.name,
      normalizeInput(event.target.value, field.value)
    );
    if (onChange) {
      onChange(event);
    }
  };
  const blurHandler = (event) => {
    form.setFieldTouched(field.name);
    if (onBlur) {
      onBlur(event);
    }
  };
  const touch = getIn(form.touched, field.name);
  const errorText = getIn(form.errors, field.name);

  return (
    <>
      <TextField
        name={field.name}
        value={field.value || ""}
        onChange={changeHandler}
        onBlur={blurHandler}
        {...otherProps}
        error={!!(touch && errorText)}
      />
      {displayErrorMessage ? (
        <FormHelperText error={Boolean(touch && errorText)}>
          {(touch && errorText) || internalhelperText}
        </FormHelperText>
      ) : (
        internalhelperText && (
          <FormHelperText>{internalhelperText}</FormHelperText>
        )
      )}
    </>
  );
};
