import React, { useEffect, useState, useMemo } from "react";
import { Paper, Grid, Typography } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import * as yup from "yup";
import { format } from "date-fns";
import FormikTextField from "../../../../Components/FormikFields/TextField";
import FormikPhoneField from "../../../../Components/FormikFields/PhoneField";
import FormikCheckBox from "../../../../Components/FormikFields/CheckBox";
import FormikSelect from "../../../../Components/FormikFields/SelectField";
import states from "../../../../constants/constants";
import Button from "../../../../Components/MuiComponents/Button";
import TNC from "../../../../Components/TNC/TNC";
import getAge from "../../../../utils";

const stateOptions = states.map(function (item) {
  return { value: item, label: item };
});

const addUser = gql`
  mutation createEnrollment($user: UserInput) {
    createEnrollment(user: $user) {
      response
      statusCode
      users {
        userId {
          id
        }
      }
    }
  }
`;

const getAddDetailsSchema = (parentFlow) => {
  let returnSchema = yup.object().shape({
    street: yup
      .string()
      .required("Enter address")
      .max(100, "Too big")
      .min(3, "Should be minimum 3 characters"),
    city: yup
      .string()
      .required("Enter city")
      .max(50, "Too big")
      .min(3, "Should be minimum 3 characters"),
    state: yup.string().required("Select state"),
    postalCode: yup
    .string("Enter 5 digit code")
    .typeError("Invalid Value")
    .required("Enter 5 digit code")
    .matches("^[0-9]{5}$", "Enter 5 digit code")
    .notOneOf(['00000'],"Enter Valid Code"),
    primaryPhone: yup
      .string()
      .required("Enter Phone Number")
      .test("validPhone", "Invalid Phone", (currentValue) => {
        if (currentValue) {
          let specialStrip = currentValue.replace(/[^\d]/g, "");
          return specialStrip.length === 10;
        }
        return false;
      }),
    tnc: yup.bool().oneOf([true], "Accept Terms and Conditions"),
  });
  if (parentFlow) {
    returnSchema = returnSchema.shape({
      parentFName: yup
        .string()
        .required("Enter First Name")
        .max(50, "Too big"),
      parentLName: yup
        .string()
        .required("Enter Last Name")
        .max(50, "Too big"),
    });
  }
  return returnSchema;
};

export default ({
  regCode,
  dispatchSave,
  goNext,
  currentState,
  basicDetails,
  setStep,
}) => {
  const [, setFormObject] = useState();
  useEffect(() => {
    setStep(3);
  }, []);
  const parentFlow = useMemo(() => getAge(basicDetails.dob) <= 18, [
    basicDetails,
  ]);
  const addDetailsSchema = useMemo(() => getAddDetailsSchema(parentFlow), [
    basicDetails,
  ]);
  const [terms, setTerms] = useState(false);
  const [termsObject, setTermsObject] = useState({});
  const [register, { loading, data, error }] = useMutation(addUser);

  const submitFunction = async (values) => {
    dispatchSave({ type: "additionalDetails", body: { ...values } });
    setFormObject(values);
    const userData = {
      password: basicDetails.password,
      firstName: basicDetails.firstName.trim(),
      lastName: basicDetails.lastName.trim(),
      birthDate: format(basicDetails.dob, "yyyy-MM-dd"),
      email: {
        email: basicDetails.userName.toLowerCase().trim(),
      },
      userName: basicDetails.userName.toLowerCase().trim(),
      phoneNumbers: [
        {
          number: values.primaryPhone,
          teleCommunicationType: "Mobile",
        },
      ],
      gender:
        basicDetails.gender.charAt(0).toUpperCase() +
        basicDetails.gender.slice(1),
      registrationCode: regCode,
      address: {
        state: values.state,
        city: values.city.trim(),
        zipCode: values.postalCode.trim(),
        street: values.street.trim(),
      },
      acceptedTermsAndCondition: {
        version: termsObject.tnc.version,
        memberName: termsObject.tnc.memberName,
        parentName:
          termsObject.tnc.parentName &&
          termsObject.tnc.parentName.trim().length > 0
            ? termsObject.tnc.parentName
            : undefined,
        legalRepresentative: termsObject.tnc.legalRepresentative,
        relationShip: termsObject.tnc.relationship,
      },
      acceptedHipaaDetails: {
        version: termsObject.hipaa.version,
        memberName: termsObject.hipaa.memberName,
        parentName:
          termsObject.hipaa.parentName &&
          termsObject.hipaa.parentName.trim().length > 0
            ? termsObject.hipaa.parentName
            : undefined,
        legalRepresentative: termsObject.hipaa.legalRepresentative,
        relationShip: termsObject.hipaa.relationship,
      },
      acceptedPrivacyDetails: {
        version: termsObject.privacy.version,
        memberName: termsObject.privacy.memberName,
        parentName:
          termsObject.privacy.parentName &&
          termsObject.privacy.parentName.trim().length > 0
            ? termsObject.privacy.parentName
            : undefined,
        legalRepresentative: termsObject.privacy.legalRepresentative,
        relationShip: termsObject.privacy.relationship,
      },
      parent: parentFlow
        ? {
            firstName: values.parentFName.trim(),
            lastName: values.parentLName.trim(),
            email: {
              email: basicDetails.userName.toLowerCase().trim(),
            },
            phoneNumbers: [
              {
                number: values.primaryPhone,
                teleCommunicationType: "Mobile",
              },
            ],
            address: {
              state: values.state.value,
              city: values.city.trim(),
              zipCode: values.postalCode.trim(),
              street: values.street.trim(),
            },
            userRoleType: "Parent",
          }
        : undefined,
      userRoleType: "Member",
      isUH: false,
      memberName: termsObject.tnc.memberName,
      isBuyUp: false,
      salesforceContactId: null,
      salesforceAccountId: null,
    };
    register({
      variables: {
        user: userData,
      },
    });
  };

  useEffect(() => {
    if (data && data.createEnrollment.statusCode === 200) {
      goNext();
    }
  }, [data]);

  const onTermsAccept = (setFieldValue) => (termsObj) => {
    setFieldValue("tnc", true, true);
    setTermsObject(termsObj);
    setTerms(false);
  };

  const onTermsEscape = (setFieldValue) => () => {
    setFieldValue("tnc", false, true);
    setTerms(false);
  };

  return (
    <>
      <Formik
        initialValues={currentState}
        validationSchema={addDetailsSchema}
        validateOnBlur
        validateOnChange={false}
        onSubmit={submitFunction}
      >
        {(props) => (
          <>
            <TNC
              show={terms}
              parentFlow={parentFlow}
              onFinalAccept={onTermsAccept(props.setFieldValue)}
              onDismiss={onTermsEscape(props.setFieldValue)}
              parentName={`${props.values.parentFName} ${props.values.parentLName}`}
              memberName={`${basicDetails.firstName} ${basicDetails.lastName}`}
              dob={
                basicDetails.dob
                  ? format(basicDetails.dob, "MM-dd-yyyy")
                  : undefined
              }
            />

            <Form>
              {parentFlow && (
                <Typography
                  component="div"
                  style={{ textAlign: "center", margin: "10px" }}
                  variant="body"
                >
                  Because you are under the age of 18, we need to collect
                  information on your parent or guardian to comply with HIPAA
                  requirements.
                </Typography>
              )}
              <Paper style={{ padding: "20px 10px" }} elevation={6}>
                <Grid container spacing={3} direction="column" justify="center">
                  <Grid item style={{ textAlign: "center" }}>
                    <Typography variant="h4">CONTACT</Typography>
                  </Grid>
                  {parentFlow && (
                    <Grid item>
                      <Grid container spacing={3} justify="center">
                        <Grid item xs={12} md={6}>
                          <Field
                            component={FormikTextField}
                            name="parentFName"
                            label="Parent First Name"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Field
                            component={FormikTextField}
                            name="parentLName"
                            label="Parent Last Name"
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  <Grid item>
                    <Grid container spacing={3} justify="center">
                      <Grid item xs={12}>
                        <Field
                          component={FormikTextField}
                          name="street"
                          label="Street Address"
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container spacing={3} justify="center">
                      <Grid item xs={12} md={6}>
                        <Field
                          component={FormikTextField}
                          name="city"
                          label="City"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          component={FormikSelect}
                          options={stateOptions}
                          name="state"
                          placeholder="State"
                          style={{ width: "82%" }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container spacing={3} justify="center">
                      <Grid item xs={12} md={6}>
                        <Field
                          component={FormikTextField}
                          name="postalCode"
                          label="Zip code"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          component={FormikPhoneField}
                          name="primaryPhone"
                          label="Phone Number"
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container spacing={3}>
                      <Grid item>
                        <Field
                          component={FormikCheckBox}
                          onClick={() => setTerms(true)}
                          name="tnc"
                          label="Accept Terms and Conditions"
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item style={{ textAlign: "center" }}>
                    <Button type="submit" loading={loading} disabled={loading}>
                      Enroll
                    </Button>
                    {error && (
                      <Typography variant="body1">
                        Unexpected Error. Please try again later.
                      </Typography>
                    )}
                    {loading && (
                      <Typography variant="body1">Registering</Typography>
                    )}
                    {data && data.createEnrollment.statusCode === 400 && (
                      <Typography variant="body1">
                        The e-mail address is already associated with an account
                        in our system. If you don&apos;t remember your account,
                        please use the &apos;Forgot Password&apos; feature.
                      </Typography>
                    )}
                    {data && data.createEnrollment.statusCode === 500 && (
                      <Typography variant="body1">
                        Registration failed.
                      </Typography>
                    )}
                    {data && data.createEnrollment.statusCode === 200 && (
                      <Typography variant="body1">
                        Registration completed successfully.
                      </Typography>
                    )}
                  </Grid>
                  <Grid
                    item
                    style={{
                      margin: "0px 15px",
                      textAlign: "center",
                      fontWeight: 300,
                    }}
                  >
                    ArmadaHealth makes every effort to protect member
                    confidentiality. We do not have any financial relationship
                    with the physicians we recommend.
                  </Grid>
                </Grid>
              </Paper>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
