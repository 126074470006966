import { useCallback, useEffect, useState } from "react";
import throttle from "lodash.throttle";

/**
 * Check if an element is in viewport

 * @param {number} offset - Number of pixels up to the observable element from the top
 * @param {number} throttleMilliseconds - Throttle observable listener, in ms
 */
export default function useVisibility(offset = 0, throttleMilliseconds = 500) {
  const [isVisible, setIsVisible] = useState(false);
  const [_curElement, _setCurElement] = useState(null);  // the element that is the reference
  const [_elementToTrack, _setElementToTrack] = useState(null); // the elemene that I am tracking
  const currentElement = useCallback((node) => {
    _setCurElement(node);
  });
  const elementToTrack = useCallback((node) => {
    _setElementToTrack(node);
  });

  const onScroll = throttle(() => {
    if (!_curElement) {
      setIsVisible(false);
      return;
    }
    const { top } = _curElement.getBoundingClientRect();
    const {bottom:refBottom} = _elementToTrack.getBoundingClientRect();
    setIsVisible(
      (alreadyShown) =>
        alreadyShown ||
        (top + offset >= 0 && top - offset <= (refBottom))
    );
  }, throttleMilliseconds);

  useEffect(() => {
    if (_elementToTrack) {
      _elementToTrack.addEventListener("scroll", onScroll);
      onScroll();
    }
    return () => {
      if (_elementToTrack != null) {
        _elementToTrack.removeEventListener("scroll", onScroll);
      }
    };
  }, [_curElement, _elementToTrack]);

  return [isVisible, currentElement, elementToTrack];
}
