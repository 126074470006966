import React, { useReducer, useState, useEffect } from "react";
import { Route, Redirect, Switch, useHistory } from "react-router-dom";
import TokenScreen from "./Token";
import BasicDetailsScreen from "./BasicDetails";
import AdditionalDetailsScreen from "./AdditionalDetails";

const initialState = {
  token: { token: "" },
  basicDetails: {
    firstName: "",
    lastName: "",
    dob: "",
    gender: "",
    userName: "",
    password: "",
    confirmPassword: "",
  },
  additionalDetails: {
    street: "",
    city: "",
    state: "",
    postalCode: "",
    primaryPhone: "",
    parentFName: "",
    parentLName: "",
    tnc: false,
  },
};

function reducer(state, action) {
  switch (action.type) {
    case "token": {
      let token = { ...state.token, ...action.body };
      return {
        ...state,
        token: { ...token },
      };
    }
    case "basicDetails": {
      let basicDetails = { ...state.basicDetails, ...action.body };
      return {
        ...state,
        basicDetails: { ...basicDetails },
      };
    }
    case "additionalDetails": {
      let additionalDetails = { ...state.additionalDetails, ...action.body };
      return {
        ...state,
        additionalDetails: { ...additionalDetails },
      };
    }
    default:
      throw new Error("unsupported action");
  }
}

export default (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [currentStep, setCurrentStep] = useState(1);
  const history = useHistory();

  const goNext = (stepNumber) => {
    if (stepNumber < 3) {
      setCurrentStep(stepNumber + 1);
    } else {
      props.registrationCompleted(
        state.basicDetails.userName,
        state.basicDetails.password
      );
    }
  };

  useEffect(() => {
    switch (currentStep) {
      case 1:
        history.push("groupid");
        break;
      case 2:
        history.push("basicDetails");
        break;
      case 3:
        history.push("additionalDetails");
        break;
      default:
        throw new Error("unsupported action");
    }
  }, [currentStep]);

  return (
    <Switch>
      {currentStep >= 1 && (
        <Route
          path={`${props.match.url}/groupid/:token`}
          render={(innerProps) => {
            return (
              <Redirect
                to={{
                  pathname: `${props.match.url}/groupid`,
                  state: { initialToken: innerProps.match.params.token },
                }}
              />
            );
          }}
        />
      )}
      {currentStep >= 1 && (
        <Route
          path={`${props.match.url}/groupid`}
          render={(routeProps) => {
            return (
              <TokenScreen
                {...routeProps}
                setStep={setCurrentStep}
                currentState={state.token}
                dispatchSave={dispatch}
                goNext={goNext}
              />
            );
          }}
        />
      )}

      {currentStep >= 2 && (
        <Route
          path={`${props.match.url}/basicDetails`}
          render={(routeProps) => (
            <BasicDetailsScreen
              {...routeProps}
              setStep={setCurrentStep}
              currentState={state.basicDetails}
              regCode={state.token.token}
              dispatchSave={dispatch}
              goNext={goNext}
            />
          )}
        />
      )}
      {currentStep >= 3 && (
        <Route
          path={`${props.match.url}/additionalDetails`}
          render={(routeProps) => (
            <AdditionalDetailsScreen
              {...routeProps}
              setStep={setCurrentStep}
              currentState={state.additionalDetails}
              regCode={state.token.token}
              basicDetails={state.basicDetails}
              dispatchSave={dispatch}
              goNext={goNext}
            />
          )}
        />
      )}
      <Redirect to={`${props.match.url}/groupid`} />
    </Switch>
  );
};
