import React from "react";
import { Hidden, Grid, Box } from "@material-ui/core";
import DesktopImage from "../../staticFiles/New_Wheel_Desktop.png";
import MobileImage from "../../staticFiles/New_Wheel_Mobile.png";

export default () => {
  return (
    <Box style={{ marginTop: 30 }}>
      <Grid container style={{ padding: "10px" }}>
        <Grid item xs={1} />
        <Grid item xs={10} style={{ textAlign: "center" }}>
          <Hidden smDown>
            <img
              alt=""
              style={{ width: "100%", maxWidth: "800px" }}
              src={DesktopImage}
            />
          </Hidden>
          <Hidden mdUp>
            <img style={{ width: "100%" }} src={MobileImage} />
          </Hidden>
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </Box>
  );
};
