import React, { useEffect, useState } from "react";
import { Paper, Grid, Typography } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { useTheme } from "@material-ui/core/styles";
import gql from "graphql-tag";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import * as yup from "yup";
import { format } from "date-fns";
import FormikTextField from "../../../Components/FormikFields/TextField";
import FormikDateField from "../../../Components/FormikFields/DateField";
import FormikCheckBox from "../../../Components/FormikFields/CheckBox";
import Button from "../../../Components/MuiComponents/Button";
import TNC from "../../../Components/TNC/TNC";
import CustomPasswordField from "../../../Components/MuiComponents/PasswordField";

const uhRegistrationSchema = yup.object().shape({
  firstName: yup
    .string()
    .required("Enter First Name")
    .max(50, "Too big"),
  lastName: yup
    .string()
    .required("Enter Last Name")
    .max(50, "Too big"),
  dob: yup
    .date()
    .typeError("Invalid Value")
    .required("DOB is required")
    .max(new Date(), "No future date"),
  ssn4: yup
    .string()
    .required("Enter SSN")
    .min(4, "Enter last 4 digits")
    .max(4, "Enter last 4 digits")
    .test("isValidSSN", "Invalid", (value) => {
      if (value) {
        let numberSSN = Number(value);
        return !isNaN(numberSSN);
      }
      return false;
    }),
  username: yup
    .string()
    .required("Enter Username")
    .max(50, "too big")
    .email("Invalid email"),
  password: yup
    .string()
    .required("Enter Password")
    .min(
      8,
      "Passwords must be at least 8 characters long and contain both numeric and alphabetic characters"
    )
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)[\040-\176]{8,}$/,
      "Passwords must be at least 8 characters long and contain both numeric and alphabetic characters"
    ),
  confirmPassword: yup
    .string()
    .required("Enter Password")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  tocAccept: yup.bool().oneOf([true], "Accept Terms and Conditions"),
});

const checkIfEnrolled = gql`
  query checkEnrollmentforUH($user: SearchUserInputforUH) {
    searchUserinProviderforUH(user: $user) {
      response
      statusCode
      provider
      existingUserData {
        __typename
        ... on UsLacrossePayLoad {
          firstName
          lastName
          street
          city
          state
          countryCode
          postalCode
          primaryPhone
          primaryEmailAddress
          identificationNumber
          birthDate
          gender
          homePhone
        }
        ... on SalesForcePayLoad {
          firstName
          lastName
          street
          city
          state
          countryCode
          postalCode
          primaryPhone
          primaryEmailAddress
          registrationCode
          birthDate
          gender
          homePhone
          salesforceContactId
          salesforceAccountId
        }
      }
    }
  }
`;

const enrollUserQuery = gql`
  mutation createEnrollment($user: UserInput) {
    createEnrollment(user: $user) {
      response
      statusCode
      users {
        userId {
          id
        }
      }
    }
  }
`;

export default ({ registrationCompleted }) => {
  const [
    chkEnr,
    { loading: chkLoading, data: chkData, error: chkerror },
  ] = useLazyQuery(checkIfEnrolled);
  const [formObject, setFormObject] = useState();
  const [enrollmentErrorMessage, setEnrollmentErrorMessage] = useState(null);

  const [terms, setTerms] = useState(false);
  const [termsObject, setTermsObject] = useState({});
  const [
    enrollUser,
    { loading: enrollLoading, data: enrollData },
  ] = useMutation(enrollUserQuery);
  const { dssBranding } = useTheme();
  const submitFunction = async (values) => {
    setEnrollmentErrorMessage(null);
    setFormObject(values);
    const userDetails = {
      email: values.username.toLowerCase(),
      userName: values.username.toLowerCase(),
      firstName: values.firstName,
      lastName: values.lastName,
      birthDate: format(values.dob, "yyyy-MM-dd"),
      SSNNumber: values.ssn4,
    };
    chkEnr({
      variables: {
        user: userDetails,
      },
    });
  };
  useEffect(() => {
    if (
      chkData &&
      chkData.searchUserinProviderforUH &&
      chkData.searchUserinProviderforUH.existingUserData
    ) {
      const {
        searchUserinProviderforUH: { existingUserData },
      } = chkData;
      const userData = {
        password: formObject.password,
        firstName: formObject.firstName.trim(),
        lastName: formObject.lastName.trim(),
        birthDate: format(formObject.dob, "yyyy-MM-dd"),
        email: {
          email: formObject.username.toLowerCase().trim(),
        },
        userName: formObject.username.toLowerCase().trim(),
        phoneNumbers: existingUserData.primaryPhone
          ? [
              {
                number: existingUserData.primaryPhone.trim(),
                teleCommunicationType: "Mobile",
              },
            ]
          : undefined,
        gender:
          existingUserData.gender.charAt(0).toUpperCase() +
          existingUserData.gender.slice(1),
        registrationCode: existingUserData.registrationCode || null,
        address: {
          state: existingUserData.state
            ? existingUserData.state.toUpperCase().trim()
            : undefined,
          city: existingUserData.city
            ? existingUserData.city.trim()
            : undefined,
          zipCode: existingUserData.postalCode
            ? existingUserData.postalCode.replace(/[^0-9]/g, "").trim()
            : undefined,
          street: existingUserData.street
            ? existingUserData.street.trim()
            : undefined,
        },
        acceptedTermsAndCondition: {
          version: termsObject.tnc.version,
          memberName: termsObject.tnc.memberName,
          parentName:
            termsObject.tnc.parentName &&
            termsObject.tnc.parentName.trim().length > 0
              ? termsObject.tnc.parentName
              : undefined,
          legalRepresentative: termsObject.tnc.legalRepresentative,
          relationShip: termsObject.tnc.relationship,
        },
        acceptedHipaaDetails: {
          version: termsObject.hipaa.version,
          memberName: termsObject.hipaa.memberName,
          parentName:
            termsObject.hipaa.parentName &&
            termsObject.hipaa.parentName.trim().length > 0
              ? termsObject.hipaa.parentName
              : undefined,
          legalRepresentative: termsObject.hipaa.legalRepresentative,
          relationShip: termsObject.hipaa.relationship,
        },
        acceptedPrivacyDetails: {
          version: termsObject.privacy.version,
          memberName: termsObject.privacy.memberName,
          parentName:
            termsObject.privacy.parentName &&
            termsObject.privacy.parentName.trim().length > 0
              ? termsObject.privacy.parentName
              : undefined,
          legalRepresentative: termsObject.privacy.legalRepresentative,
          relationShip: termsObject.privacy.relationship,
        },
        identificationNumber: existingUserData.identificationNumber
          ? existingUserData.identificationNumber.trim()
          : undefined,
        userRoleType: "Member",
        isUH: true,
        SSNNumber: formObject.ssn4,
        memberName: termsObject.tnc.memberName,
        isBuyUp: false,
        salesforceContactId: existingUserData.salesforceContactId,
        salesforceAccountId: existingUserData.salesforceAccountId,
      };
      enrollUser({
        variables: {
          user: userData,
        },
      });
    }
  }, [chkData]);

  useEffect(() => {
    if (enrollData) {
      const enrollmentstatusCode = enrollData.createEnrollment.statusCode;
      if (enrollmentstatusCode === 200) {
        registrationCompleted(
          formObject.username.toLowerCase().trim(),
          formObject.password
        );
      } else if (enrollmentstatusCode === 400) {
        setEnrollmentErrorMessage(enrollData.createEnrollment.response);
      } else if (enrollmentstatusCode === 500) {
        setEnrollmentErrorMessage(enrollData.createEnrollment.response);
      } else if (enrollmentstatusCode === 409) {
        setEnrollmentErrorMessage(
          "An existing account has been found matching these details"
        );
      } else {
        setEnrollmentErrorMessage(
          "Whoops! The entered details could not match in our database."
        );
      }
    }
  }, [enrollData]);

  const onTermsAccept = (setFieldValue) => (termsObj) => {
    setFieldValue("tocAccept", true, true);
    setTermsObject(termsObj);
    setTerms(false);
  };

  const onTermsEscape = (setFieldValue) => () => {
    setFieldValue("tocAccept", false, true);
    setTerms(false);
  };

  return (
    <>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          dob: "",
          ssn4: "",
          username: "",
          password: "",
          confirmPassword: "",
          tocAccept: false,
        }}
        validationSchema={uhRegistrationSchema}
        validateOnBlur
        validateOnChange={false}
        onSubmit={submitFunction}
      >
        {(props) => {
          return (
            <>
              <TNC
                show={terms}
                onFinalAccept={onTermsAccept(props.setFieldValue)}
                onDismiss={onTermsEscape(props.setFieldValue)}
                memberName={`${props.values.firstName} ${props.values.lastName}`}
                dob={
                  props.values.dob
                    ? format(props.values.dob, "MM-dd-yyyy")
                    : undefined
                }
              />
              <Form>
                <Paper style={{ padding: "20px 10px" }} elevation={6}>
                  <Grid
                    container
                    spacing={4}
                    direction="column"
                    justify="center"
                  >
                    <Grid item style={{ textAlign: "center" }}>
                      <Typography variant="h4">PROFILE</Typography>
                    </Grid>
                    <Grid item>
                      <Grid container spacing={3} justify="center">
                        <Grid item xs={12} md={6}>
                          <Field
                            component={FormikTextField}
                            name="firstName"
                            label="first name"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Field
                            component={FormikTextField}
                            name="lastName"
                            label="last name"
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container spacing={3} justify="center">
                        <Grid item xs={12} md={6}>
                          <Field
                            component={FormikDateField}
                            name="dob"
                            openToDate={new Date("2000-01-01")}
                            maxDate={new Date()}
                            textFieldProps={{
                              label: "Date of Birth",
                              fullWidth: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Field
                            component={FormikTextField}
                            name="ssn4"
                            label="last 4 digits SSN"
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container spacing={3} justify="center">
                        <Grid item xs={12}>
                          <Field
                            component={FormikTextField}
                            name="username"
                            label="Email(Username)"
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container spacing={3} justify="center">
                        <Grid item xs={12} md={6}>
                          <Field
                            component={FormikTextField}
                            type="password"
                            name="password"
                            label="Password"
                            textFieldComponent={CustomPasswordField}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Field
                            component={FormikTextField}
                            type="password"
                            name="confirmPassword"
                            label="Confirm Password"
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container spacing={3}>
                        <Grid item>
                          <Field
                            component={FormikCheckBox}
                            onClick={() => setTerms(true)}
                            name="tocAccept"
                            label="Accept Terms and Condition"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item style={{ textAlign: "center" }}>
                      <Button
                        type="submit"
                        loading={chkLoading || enrollLoading}
                        disabled={chkLoading || enrollLoading}
                      >
                        Submit
                      </Button>
                      {chkerror && (
                        <Typography variant="body1">
                          Unexpected Error. Please try again later.
                        </Typography>
                      )}
                      {chkLoading && (
                        <Typography variant="body1">
                          Checking your enrollment
                        </Typography>
                      )}
                      {chkData &&
                        chkData.searchUserinProviderforUH.response ===
                          "COMPLETED" && (
                          <Typography variant="body1">
                            The e-mail address is already associated with an
                            account in our system. If you don&apos;t remember
                            your account, please use the &apos;Forgot
                            Password&apos; feature.
                          </Typography>
                        )}
                      {chkData &&
                        chkData.searchUserinProviderforUH.response ===
                          "NOT_AVAILABLE" && (
                          <Typography variant="body1">
                            Whoops! The entered details could not match in our
                            database. Please enter valid details to enroll. Need
                            help? Contact us at{" "}
                            <a href={`tel:${dssBranding.phoneWithoutSymbols}`}>
                              {dssBranding.phone}
                            </a>
                          </Typography>
                        )}
                      {enrollmentErrorMessage && (
                        <Typography variant="body1">
                          {enrollmentErrorMessage}
                        </Typography>
                      )}
                    </Grid>
                    <Grid
                      item
                      style={{
                        margin: "0px 15px",
                        textAlign: "center",
                        fontWeight: 300,
                      }}
                    >
                      ArmadaHealth makes every effort to protect member
                      confidentiality. We do not have any financial relationship
                      with the physicians we recommend.
                    </Grid>
                  </Grid>
                </Paper>
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};
