import React, { useState } from "react";
import {
  InputAdornment,
  Tooltip,
  IconButton,
  ClickAwayListener,
} from "@material-ui/core";
import HelpOutlinedIcon from "@material-ui/icons/HelpOutlined";
import TextField from "./TextField";

export default (props) => {
  const [toolTipOpen, setToolTipOpen] = useState(false);
  const handleTooltipClose = () => setToolTipOpen(false);
  const handleTooltipOpen = () => setToolTipOpen(true);

  return (
    <TextField
      type="password"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <Tooltip
                onClose={handleTooltipClose}
                open={toolTipOpen}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title="Passwords must be at least 8 characters long and contain both numeric and alphabetic characters"
                arrow
              >
                <IconButton onClick={handleTooltipOpen} edge="end">
                  <HelpOutlinedIcon color="primary" fontSize="medium" />
                </IconButton>
              </Tooltip>
            </ClickAwayListener>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};
