import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { createHttpLink } from "apollo-link-http";
import { ApolloProvider } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";
import fetch from "unfetch";
import { concat } from "apollo-link";
import { onError } from "apollo-link-error";
import { useTheme } from "@material-ui/core/styles";
import DefaultPrivateRouter from "./DefaultPrivateRouter";
import { AxiosProvider } from "../../context/axiosAuthenticated";
import { logPageView } from '../../analytics';

const authMiddleware = setContext(() => {
  return {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token") || ""}`,
    },
  };
});

export default (props) => {
  const { footerHeight } = useTheme();
  const history = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    logPageView(history);
  }, [history]);

  const errorCallback = (error) => {
    if (error) {
      if (error.response.status === 401 || error.response.status === 403) {
        history.push({
          pathname: "/login",
          search: `?returnTo=${pathname}`,
          state: { sessionTimeout: true },
        });
      } else {
        throw error;
      }
    } else {
      history.push({
        pathname: "/login",
        search: `?returnTo=${pathname}`,
        state: { sessionTimeout: true },
      });
    }
  };
  const logoutLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        if (err.extensions.code === "UNAUTHENTICATED") {
          errorCallback();
        }
      }
    }
  });

  const client = React.useMemo(
    () =>
      new ApolloClient({
        cache: new InMemoryCache(),
        link: logoutLink.concat(
          concat(authMiddleware, createHttpLink({ uri: `/graphql`, fetch }))
        ),
        defaultOptions: {
          watchQuery: {
            fetchPolicy: "no-cache",
            errorPolicy: "ignore",
          },
          query: {
            fetchPolicy: "no-cache",
            errorPolicy: "all",
          },
        },
      }),
    []
  );

  return (
    <div style={{ marginBottom: footerHeight }}>
      <AxiosProvider errorCallback={errorCallback}>
        <ApolloProvider client={client}>
          <DefaultPrivateRouter {...props} />
        </ApolloProvider>
      </AxiosProvider>
    </div>
  );
};
