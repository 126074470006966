import React from "react";
import { Grid, Typography, Container, Box, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, Link } from "react-router-dom";
import BuyUpPaymentButton from "./Components/BuyUpPaymentButton";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
  },
  buyupCard: {
    minHeight: theme.spacing(50),
    textAlign: "center",
    borderRadius: 4,
    border: "1px solid #dadce0",
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  buyupCardTitle: {
    maxWidth: "100%",
    wordWrap: "break-word",
  },
}));

export default (props) => {
  const classes = useStyles();
  const history = useHistory();
  let possibleBuyUps = [];
  if (
    props.buyUpData &&
    props.buyUpData.buyUps &&
    props.buyUpData.buyUps.length > 0
  ) {
    possibleBuyUps = props.buyUpData.buyUps.filter((ele) => ele.isUpgrade);
  }

  return (
    <Box className={classes.root}>
      <Container maxWidth="lg">
        {props.buyUpData &&
          !(
            props.buyUpData.currentCoverage === "None" ||
            props.buyUpData.currentCoverage === null
          ) && (
            <>
              <Typography
                variant="body2"
                gutterBottom
                style={{ textAlign: "center" }}
              >
                {`Go to `} <Link to="./"> Dashboard</Link>
              </Typography>
              <br />
              <Typography
                variant="h6"
                gutterBottom
                style={{ textAlign: "center" }}
              >
                {`Your current plan is :`}{" "}
                <span style={{ textDecoration: "underline" }}>
                  {props.buyUpData.currentCoverage}
                </span>
              </Typography>
            </>
          )}

        {props.buyUpData &&
          (props.buyUpData.currentCoverage === "None" ||
            props.buyUpData.currentCoverage === null) &&
          possibleBuyUps.length > 0 && (
            <>
              <Typography
                variant="h6"
                gutterBottom
                style={{ textAlign: "center" }}
                onClick={() => history.push("/private")}
              >
                Choose the best plan for you
              </Typography>
            </>
          )}

        <br />
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={6}
        >
          {possibleBuyUps.length > 0 &&
            possibleBuyUps.map((ele) => (
              <Grid item xs={12} md={6} lg={5} key={ele.id}>
                <Paper elevation={6}>
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    className={classes.buyupCard}
                  >
                    <Typography
                      variant="h5"
                      color="textSecondary"
                      gutterBottom
                      className={classes.buyupCardTitle}
                    >
                      {`${ele.title}`.toUpperCase()}
                    </Typography>
                    <br />
                    <Typography variant="h5" color="textSecondary" gutterBottom>
                      {`$${(ele.price / 100).toFixed(2)}`}
                    </Typography>
                    <br />
                    <Typography variant="body1" component="p">
                      {ele.productDescription}
                    </Typography>
                    <br />
                    <div>
                      <BuyUpPaymentButton
                        stripeKey={props.buyUpData.stripeKey}
                        plan={ele}
                        email={props.buyUpData.userName}
                      />
                    </div>
                    <br />
                  </Grid>
                </Paper>
              </Grid>
            ))}
          {possibleBuyUps.length === 0 && (
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <br />
              <Typography
                variant="h5"
                style={{ textAlign: "center", padding: 10 }}
                gutterBottom
              >
                No upgrade options available at this moment
              </Typography>
            </Grid>
          )}
        </Grid>
      </Container>
    </Box>
  );
};
