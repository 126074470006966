import React from "react";
import { FormHelperText } from "@material-ui/core";
import { getIn } from "formik";
import FormControl from "@material-ui/core/FormControl";
import Select from "../MuiComponents/Select";

export default function ({
  field,
  form,
  onChange,
  onBlur,
  variant,
  placeholder,
  value,
  helperText,
  displayErrorMessage = true,
  isDisabled,
  ...otherProps
}) {
  const changeHandler = (evt) => {
    form.setFieldValue(field.name, evt.value);

    if (onChange) {
      onChange(evt);
    }
  };
  const touch = getIn(form.touched, field.name);
  const errorText = getIn(form.errors, field.name);
  return (
    <>
      <FormControl variant="filled" fullWidth>
        <Select
          {...otherProps}
          isDisabled={isDisabled}
          value={{ label: field.value, value: field.value }}
          onChange={changeHandler}
          textFieldProps={{
            error: Boolean(touch && errorText),
            variant: "outlined",
            label: placeholder,
            size: "small",
            disabled: isDisabled,
          }}
          placeholder=""
        />
      </FormControl>

      {displayErrorMessage ? (
        <FormHelperText error={Boolean(touch && errorText)}>
          {(touch && errorText) || helperText}
        </FormHelperText>
      ) : (
        helperText && <FormHelperText>{helperText}</FormHelperText>
      )}
    </>
  );
}
