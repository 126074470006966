import React from "react";
import {
  RadioGroup,
  FormHelperText,
  FormControl,
  FormLabel,
} from "@material-ui/core";
import { getIn } from "formik";

export default function ({
  field,
  form,
  name,
  label,
  onChange,
  onBlur,
  helperText,
  error,
  children,
  displayErrorMessage = true,
  ...otherProps
}) {
  const changeHandler = (event) => {
    form.setFieldValue(field.name, event.target.value);
    if (onChange) {
      onChange(event);
    }
  };
  const blurHandler = (event) => {
    form.setFieldTouched(field.name);
    if (onBlur) {
      onBlur(event);
    }
  };
  const touch = getIn(form.touched, field.name);
  const errorText = getIn(form.errors, field.name);

  return (
    <FormControl
      fullWidth
      component="fieldset"
      error={Boolean(touch && errorText)}
    >
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup
        name={field.name}
        value={field.value || ""}
        key={field.name}
        onChange={changeHandler}
        onBlur={blurHandler}
        {...otherProps}
      >
        {children}
      </RadioGroup>
      {displayErrorMessage ? (
        <FormHelperText error={Boolean(touch && errorText)}>
          {(touch && errorText) || helperText}
        </FormHelperText>
      ) : (
        helperText && <FormHelperText>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
}
