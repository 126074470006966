import React, { useContext } from "react";
import axios from "axios";

const axiosContext = React.createContext();
export const useAxios = () => useContext(axiosContext);

export const AxiosProvider = ({ children, errorCallback }) => {
  const instance = axios.create({
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` || "" },
  });
  instance.defaults.timeout = 20000;
  instance.interceptors.response.use(
    (response) => response,
    function (error) {
      errorCallback(error);
    }
  );
  return (
    <axiosContext.Provider
      value={{
        axiosInstance: instance,
      }}
    >
      {children}
    </axiosContext.Provider>
  );
};
