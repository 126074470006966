import React, { useContext, useState } from "react";

const currentUserContext = React.createContext();
export const useCurrentUser = () => useContext(currentUserContext);

export const CurrentUserProvider = ({ children }) => {
  let [currentUser, _setCurrentUser] = useState({});

  return (
    <currentUserContext.Provider
      value={{
        currentUser,
        setCurrentUser: (userObj) => {
          _setCurrentUser(userObj);
        },
        deleteCurrentUser: () => {
          _setCurrentUser({});
        },
      }}
    >
      {children}
    </currentUserContext.Provider>
  );
};
