import React, { useEffect, useState } from "react";
import {
  Paper,
  Grid,
  Typography,
  Radio,
  FormControlLabel,
} from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/react-hooks";
import * as yup from "yup";
import { format } from "date-fns";
import FormikTextField from "../../../../Components/FormikFields/TextField";
import FormikDateField from "../../../../Components/FormikFields/DateField";
import FormikRadioGroup from "../../../../Components/FormikFields/RadioGroup";
import CustomPasswordField from "../../../../Components/MuiComponents/PasswordField";
import Button from "../../../../Components/MuiComponents/Button";

const checkEnroll = gql`
  query checkEnrollment($user: SearchUserInput, $buyUp: BuyUpInput) {
    searchUserinProvider(user: $user, buyUp: $buyUp) {
      response
      statusCode
      provider
      existingUserData {
        __typename
        ... on SalesForcePayLoad {
          street
          city
          state
          countryCode
          postalCode
          primaryPhone
        }
      }
    }
  }
`;

const basicDetailsSchema = yup.object().shape({
  firstName: yup
    .string()
    .required("Enter First Name")
    .max(50, "Too big"),
  lastName: yup
    .string()
    .required("Enter Last Name")
    .max(50, "Too big"),
  dob: yup
    .date()
    .typeError("Invalid Value")
    .required("DOB is required")
    .max(new Date(), "No future date"),
  gender: yup.string().required("Select Gender"),
  userName: yup
    .string()
    .required("Enter Username")
    .max(50, "too big")
    .email("Invalid email"),
  password: yup
    .string()
    .required("Enter Password")
    .min(
      8,
      "Passwords must be at least 8 characters long and contain both numeric and alphabetic characters"
    )
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)[\040-\176]{8,}$/,
      "Passwords must be at least 8 characters long and contain both numeric and alphabetic characters"
    ),
  confirmPassword: yup
    .string()
    .required("Enter Password")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

export default ({ regCode, dispatchSave, goNext, currentState, setStep }) => {
  useEffect(() => {
    setStep(2);
  }, []);
  const [chkEnr, { loading, data, error }] = useLazyQuery(checkEnroll);
  const [formObject, setFormObject] = useState();
  const submitFunction = async (values) => {
    setFormObject(values);
    const buyUp = {
      isBuyUp: false,
    };
    const userDetails = {
      email: values.userName.toLowerCase(),
      registrationCode: regCode,
      userName: values.userName.toLowerCase(),
      firstName: values.firstName,
      lastName: values.lastName,
      birthDate: format(values.dob, "yyyy-MM-dd"),
    };
    chkEnr({
      variables: {
        user: userDetails,
        buyUp,
      },
    });
  };

  useEffect(() => {
    if (
      data &&
      data.searchUserinProvider &&
      (data.searchUserinProvider.response === "NOT_AVAILABLE" ||
        data.searchUserinProvider.response === "SUCCESS")
    ) {
      dispatchSave({ type: "basicDetails", body: { ...formObject } });
      const additionalDetails = data.searchUserinProvider.existingUserData;
      if (additionalDetails) {
        dispatchSave({ type: "additionalDetails", body: additionalDetails });
      }
      goNext(2);
    }
  }, [data]);

  return (
    <>
      <Formik
        initialValues={currentState}
        validationSchema={basicDetailsSchema}
        validateOnBlur
        validateOnChange={false}
        onSubmit={submitFunction}
      >
        {() => (
          <>
            <Form>
              <Paper style={{ padding: "20px 10px" }} elevation={6}>
                <Grid container spacing={6} direction="column" justify="center">
                  <Grid item style={{ textAlign: "center" }}>
                    <Typography variant="h4">PROFILE</Typography>
                  </Grid>
                  <Grid item>
                    <Grid container spacing={3} justify="center">
                      <Grid item xs={12} md={6}>
                        <Field
                          component={FormikTextField}
                          name="firstName"
                          label="first name"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          component={FormikTextField}
                          name="lastName"
                          label="last name"
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container spacing={3} justify="center">
                      <Grid item xs={12} md={6}>
                        <Field
                          component={FormikDateField}
                          name="dob"
                          openToDate={new Date("2000-01-01")}
                          maxDate={new Date()}
                          textFieldProps={{
                            label: "Date of Birth",
                            fullWidth: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field component={FormikRadioGroup} name="gender" row>
                          <FormControlLabel
                            value="Male"
                            control={<Radio color="primary" />}
                            label="Male"
                          />
                          <FormControlLabel
                            value="Female"
                            control={<Radio color="primary" />}
                            label="Female"
                          />
                        </Field>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container spacing={3} justify="center">
                      <Grid item xs={12}>
                        <Field
                          component={FormikTextField}
                          name="userName"
                          label="Email (Username)"
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container spacing={3} justify="center">
                      <Grid item xs={12} md={6}>
                        <Field
                          component={FormikTextField}
                          type="password"
                          name="password"
                          label="Password"
                          textFieldComponent={CustomPasswordField}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          component={FormikTextField}
                          type="password"
                          name="confirmPassword"
                          label="Confirm Password"
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item style={{ textAlign: "center" }}>
                    <Button type="submit" loading={loading} disabled={loading}>
                      Next
                    </Button>
                    {error && (
                      <Typography variant="body1">
                        Unexpected Error. Please try again later.
                      </Typography>
                    )}
                    {loading && (
                      <Typography variant="body1">
                        Checking your enrollment
                      </Typography>
                    )}
                    {data &&
                      data.searchUserinProvider.response === "COMPLETED" && (
                        <Typography variant="body1">
                          The e-mail address is already associated with an
                          account in our system. If you don&apos;t remember your
                          account, please use the &apos;Forgot Password&apos;
                          feature.
                        </Typography>
                      )}
                  </Grid>
                  <Grid
                    item
                    style={{
                      margin: "0px 15px",
                      textAlign: "center",
                      fontWeight: 300,
                    }}
                  >
                    ArmadaHealth makes every effort to protect member
                    confidentiality. We do not have any financial relationship
                    with the physicians we recommend.
                  </Grid>
                </Grid>
              </Paper>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
