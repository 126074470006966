import React, { useState } from "react";
import {
  Paper,
  Grid,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  Box,
} from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import Button from "../../Components/MuiComponents/Button";
import TNC from "../../Components/TNC/TNC";
import { useCurrentUser } from "../../context/CurrentUser";

const useStyles = makeStyles(() => ({
  label: {
    textAlign: "start",
  },
}));

export default ({
  buyupObj: { isPCPRequestSupported, buyUps, buyupIdPurchased },
}) => {
  const classes = useStyles();
  const { dssBranding } = useTheme();
  const [showTerms, setShowTerms] = useState(false);
  const history = useHistory();
  const { currentUser } = useCurrentUser();
  const [selectedDoctorOption, setSelectedDoctorOption] = useState(() => {
    return isPCPRequestSupported ? "2" : "1";
  });
  const isUpgradeShown = (function () {
    let returnOption = false;
    if (buyUps && buyUps.length) {
      let plans = buyUps.filter((obj) => {
        return obj.status === "Active" && obj.isUpgrade == true;
      });
      let buyupIdPurchasedPosition = buyupIdPurchased
        ? plans.findIndex((row) => row.id == buyupIdPurchased)
        : null;
      let nextPlan = null;
      if (buyupIdPurchasedPosition != null) {
        nextPlan =
          buyupIdPurchasedPosition + 1 < plans.length
            ? plans[buyupIdPurchasedPosition + 1]
            : null;
      } else {
        [nextPlan] = plans;
      }
      returnOption = !!nextPlan;
    }
    return returnOption;
  })();

  const onTermsAccept = () => {
    setShowTerms(false);
    history.push(`./raisecase/${selectedDoctorOption}`);
  };

  const onTermsEscape = () => {
    setShowTerms(false);
  };

  const handleSelectedDoctorOptionChange = (event) => {
    setSelectedDoctorOption(event.target.value);
  };

  const caseServiceRequested = () => {
    if (dssBranding.acceptTermsPerCase) {
      setShowTerms(true);
    } else {
      history.push(`./raisecase/${selectedDoctorOption}`);
    }
  };

  return (
    <Box style={{ marginTop: 30 }}>
      <TNC
        show={showTerms}
        onFinalAccept={onTermsAccept}
        onDismiss={onTermsEscape}
        memberName={`${currentUser.firstName} ${currentUser.lastName}`}
        dob={currentUser.birthDate}
        parentFlow={currentUser.parent && currentUser.parent.userId}
        parentName={
          currentUser.parent &&
          currentUser.parent.userId &&
          `${currentUser.parent.firstName} ${currentUser.parent.lastName}`
        }
      />
      <Grid container style={{ padding: "10px" }}>
        <Grid item xs={1} md={3} />
        <Grid item xs={10} md={6}>
          <Paper style={{ display: "flex", padding: "20px" }} elevation={6}>
            <Grid container spacing={4} direction="column" justify="center">
              <Grid item style={{ textAlign: "center" }}>
                <Typography variant="h4">REQUEST DOCTORS</Typography>
              </Grid>
              {isPCPRequestSupported && (
                <Grid item style={{ textAlign: "center" }}>
                  <RadioGroup
                    style={{ display: "inline-flex" }}
                    aria-label="doctor selection"
                    name="doctorselected"
                    value={selectedDoctorOption}
                    onChange={handleSelectedDoctorOptionChange}
                  >
                    <FormControlLabel
                      classes={{ label: classes.label }}
                      value="2"
                      control={<Radio color="primary" />}
                      label="Primary Care Physician (PCP)"
                    />
                    <FormControlLabel
                      value="1"
                      control={<Radio color="primary" />}
                      label="Specialist"
                    />
                    <FormControlLabel
                      value="3"
                      control={<Radio color="primary" />}
                      label="Both"
                    />
                  </RadioGroup>
                </Grid>
              )}
              <Grid item style={{ textAlign: "center" }}>
                <Button
                  style={{ margin: "5px" }}
                  onClick={caseServiceRequested}
                >
                  Get Started
                </Button>
                {isUpgradeShown && (
                  <Button
                    style={{ margin: "5px" }}
                    color="primary"
                    variant="outlined"
                    component={Link}
                    to="./buyup"
                  >
                    Upgrade Options
                  </Button>
                )}
                <Button
                  variant="text"
                  color="primary"
                  component={Link}
                  to="./howitworks"
                >
                  How it works
                </Button>
              </Grid>
              <Grid
                item
                style={{
                  margin: "5px 15px",
                  textAlign: "center",
                  fontWeight: 300,
                }}
              >
                If this is a medical emergency, please call 911 or go to your
                local emergency room. ArmadaHealth does not practice medicine.
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={1} md={3} />
      </Grid>
    </Box>
  );
};
