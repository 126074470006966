import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Container,
  Box,
  Typography,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Slide from "@material-ui/core/Slide";
import { useHistory } from "react-router-dom";
import UpgradePaymentButton from "./Components/UpgradePaymentButton";
import { useAxios } from "../../context/axiosAuthenticated";
import Button from "../../Components/MuiComponents/Button";
import InfoDialog from "../../Components/MuiComponents/InfoDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
  },
  accountInfoCard: {
    height: 500,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(1),
  },
  hostoryBox: {
    height: 300,
    overflow: "hidden",
    "&:hover": {
      overflowY: "scroll",
    },
  },
  note: {
    paddingTop: theme.spacing(4),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function UserTransactionView(props) {
  const { userTransaction } = props;
  let title = "NA";
  let description = `${new Date(
    userTransaction.chargeDate
  ).toLocaleDateString()}`;
  let actionTitle =
    userTransaction.buyUpPossiblity && userTransaction.buyUpPossiblity.title
      ? userTransaction.buyUpPossiblity.title
      : "NA";
  if (userTransaction.chargeType === "User Initiated") {
    title = `You have purchased ${actionTitle} `;
  } else if (userTransaction.chargeType === "End Subscription") {
    title = `You have cancelled Auto Renewal for ${actionTitle}`;
  } else if (userTransaction.chargeType === "Auto Renewed") {
    title = `Plan ${actionTitle} auto renewed`;
  } else if (userTransaction.chargeType === "Create Subscription") {
    title = `Auto Renewal setup for ${actionTitle}`;
  }
  return (
    <Box>
      <Typography variant="body2" component="p">
        {title}
      </Typography>
      <Typography variant="body1" component="p" color="textSecondary">
        {description}
      </Typography>
    </Box>
  );
}

export default (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { axiosInstance } = useAxios();
  const [cardDetails, setCardDetails] = useState(null);
  const [userTransactions, setUserTransactions] = useState(null);
  const [cancelAutoRenualPopup, setCancelAutoRenualPopup] = useState(false);
  const [cancelReason, setCancelReason] = useState(null);
  const [loading, setLoading] = useState(false);
  const [autoRenualAlert, setAutoRenualAlert] = useState(false);
  const [autoRenualAlertText, setAutoRenualAlertText] = useState(null);

  const handleClose = () => {
    setCancelAutoRenualPopup(false);
  };

  async function fetchCardDetails() {
    let { data } = await axiosInstance.get(
      "/buyupapiauth/getCustomerCardDetails"
    );
    setCardDetails(data);
  }

  async function fetchUsertransactions() {
    let { data } = await axiosInstance.get("/buyupapiauth/getusertransactions");
    setUserTransactions(data);
  }

  async function sendCancelAutoRenewal() {
    try {
      setLoading(true);
      await axiosInstance.post("/buyupapiauth/cancelactivesubscription", {
        cancelationReason: cancelReason,
      });
      setCancelAutoRenualPopup(false);
      setLoading(false);
      setAutoRenualAlertText("Cancelled Successfully");
      setAutoRenualAlert(true);
    } catch (err) {
      setLoading(false);
      setAutoRenualAlertText("Error Cancelling Auto Renewal");
      setAutoRenualAlert(true);
    }
  }

  async function sendEnableAutoRenewal() {
    try {
      setLoading(true);
      await axiosInstance.post("/buyupapiauth/resubscribe", {});
      setLoading(false);
      setAutoRenualAlertText("Auto Renewal enabled succcessfully");
      setAutoRenualAlert(true);
    } catch (err) {
      setLoading(false);
      setAutoRenualAlertText("Error Enabling Auto Renewal");
      setAutoRenualAlert(true);
    }
  }

  function onOkay() {
    history.go(0);
  }

  function openBuyup() {
    history.push("/private/buyup");
  }

  useEffect(() => {
    fetchCardDetails();
    fetchUsertransactions();
  }, []);

  const autoRenialButtonState = cancelReason === null;

  return (
    <>
      <Box className={classes.root}>
        <Container maxWidth="lg">
          <Grid
            spacing={4}
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item xs={12} md={4}>
              <Paper elevation={6} className={classes.accountInfoCard}>
                <Box>
                  <Typography
                    variant="h5"
                    gutterBottom
                    style={{ textAlign: "center" }}
                  >
                    ENROLLMENT
                  </Typography>
                  <br />
                  <br />
                  <Typography variant="body2" component="p">
                    Membership
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    color="textSecondary"
                  >
                    {props.buyUpData.currentCoverage || "No Active Membership"}
                  </Typography>
                  <br />
                  <br />
                  <Typography variant="body2" component="p">
                    Expiration
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    color="textSecondary"
                  >
                    {props.buyUpData.buyupExpiryDate
                      ? new Date(
                          props.buyUpData.buyupExpiryDate
                        ).toLocaleDateString()
                      : "NA"}
                  </Typography>
                  <br />
                  <br />
                  <Typography variant="body2" component="p">
                    Next Charge
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    color="textSecondary"
                  >
                    {props.buyUpData.nextSubscriptionDate
                      ? new Date(
                          props.buyUpData.nextSubscriptionDate
                        ).toLocaleDateString()
                      : "NA"}
                  </Typography>
                  <br />
                  <br />
                </Box>
                <Box>
                  <Box style={{ textAlign: "center" }}>
                    {props.buyUpData.nextSubscriptionDate !== null ? (
                      <Button
                        color="primary"
                        variant="outlined"
                        loading={loading}
                        onClick={() => setCancelAutoRenualPopup(true)}
                      >
                        Cancel Auto Renewal
                      </Button>
                    ) : (
                      <Button
                        color="primary"
                        variant="outlined"
                        loading={loading}
                        onClick={() => sendEnableAutoRenewal()}
                      >
                        Enable Auto Renewal
                      </Button>
                    )}
                  </Box>
                </Box>
              </Paper>
            </Grid>

            <Grid item xs={12} md={4}>
              <Paper elevation={6} className={classes.accountInfoCard}>
                <Box>
                  <Typography
                    variant="h5"
                    gutterBottom
                    style={{ textAlign: "center" }}
                  >
                    PAYMENT
                  </Typography>
                  <br />
                  <br />
                  <Typography variant="body2" component="p">
                    Card Number
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    color="textSecondary"
                  >
                    {cardDetails
                      ? `XXXX - XXXX - XXXX - ${cardDetails.last4} `
                      : "loading..."}
                  </Typography>
                  <br />
                  <br />
                  <Typography variant="body2" component="p">
                    Expiration
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    color="textSecondary"
                  >
                    {cardDetails
                      ? `${cardDetails.exp_month} / ${cardDetails.exp_year}`
                      : "loading..."}
                  </Typography>
                  <br />
                  <br />
                </Box>
                <Box>
                  <Box style={{ textAlign: "center" }}>
                    <UpgradePaymentButton
                      stripeKey={props.buyUpData.stripeKey}
                      email={props.buyUpData.userName}
                    />
                  </Box>
                </Box>
              </Paper>
            </Grid>

            <Grid item xs={12} md={4}>
              <Paper
                style={{ display: "flex" }}
                elevation={6}
                className={classes.accountInfoCard}
              >
                <Box>
                  <Typography
                    variant="h5"
                    color="textPrimary"
                    gutterBottom
                    style={{ textAlign: "center" }}
                  >
                    HISTORY
                  </Typography>
                  <br />
                  <br />
                  <Box className={classes.hostoryBox}>
                    {userTransactions &&
                      userTransactions.map((ele) => (
                        <Box key={ele.id}>
                          <UserTransactionView userTransaction={ele} />
                          <br />
                          <Divider />
                          <br />
                        </Box>
                      ))}
                    {userTransactions === null && (
                      <Typography variant="body1" component="p">
                        Loading...
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box>
                  <Box style={{ textAlign: "center" }}>
                    <Button onClick={() => openBuyup()}>Upgrade Options</Button>
                  </Box>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Container maxWidth="md" className={classes.note}>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item>
            <Typography
              variant="body1"
              gutterBottom
              style={{
                margin: "0px 15px",
                textAlign: "center",
                fontWeight: 300,
              }}
            >
              ArmadaHealth makes every effort to protect member confidentiality.
              We do not have any financial relationship with the physicians we
              recommend.
            </Typography>
          </Grid>
        </Grid>
      </Container>

      {/** dialogs */}
      <Dialog
        open={cancelAutoRenualPopup}
        onClose={handleClose}
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle id="alert-dialog-title">
          Please choose reason for cancellation
        </DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <FormLabel component="legend" />
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={cancelReason}
              onChange={(e) => setCancelReason(e.target.value)}
            >
              <FormControlLabel
                value="Price is too high"
                control={<Radio />}
                label="Price is too high"
              />
              <FormControlLabel
                value="Didn't utilize the service"
                control={<Radio />}
                label="Didn't utilize the service"
              />
              <FormControlLabel
                value="I wasn't satisfied with the service"
                control={<Radio />}
                label="I wasn't satisfied with the service"
              />
              <FormControlLabel
                value="Other"
                control={<Radio />}
                label="Other"
              />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            color="primary"
            variant="outlined"
            onClick={sendCancelAutoRenewal}
            autoFocus
            disabled={autoRenialButtonState || loading}
            loading={loading}
          >
            Cancel Auto Renewal
          </Button>
        </DialogActions>
      </Dialog>
      <InfoDialog
        open={autoRenualAlert}
        description={autoRenualAlertText}
        onOkay={() => {
          setAutoRenualAlert(false);
          onOkay();
        }}
      />
      {/** dialogs */}
    </>
  );
};
