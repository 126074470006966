import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress, Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  labelOverrides: {
    fontWeight: "bold",
    textTransform: "capitalize",
    fontSize: "0.975rem",
  },
}));

export default (props) => {
  const classes = useStyles();
  const { loading, children, ...other } = props;
  return (
    <div className={classes.wrapper}>
      <Button
        classes={{ label: classes.labelOverrides }}
        variant="contained"
        disabled={loading}
        color="secondary"
        {...other}
        style={{ minWidth: "200px", minHeight: "32px", borderRadius: "15px" }}
      >
        {children}
      </Button>
      {loading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </div>
  );
};
