import React, { useState, useEffect } from "react";
import { useTheme } from "@material-ui/core/styles";
import {
  Grid,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/react-hooks";

import { useHistory } from "react-router-dom";
import MultiStepRegistration from "./MultiStepRegistration/index";
import Header from "./Header";
import UHRegistration from "./UHRegistration";

const loginQuery = gql`
  query login($user: LoginInput) {
    login(user: $user) {
      response
      statusCode
      token
    }
  }
`;

export default function (props) {
  const { dssBranding } = useTheme();
  const history = useHistory();
  const [loginFlow, setLoginFLow] = useState(false);
  const [userName, setuserName] = useState(null);
  const [login, { loading, data, error }] = useLazyQuery(loginQuery);

  const handleDialogClose = () => {
    setLoginFLow(false);
  };

  useEffect(() => {
    if (data && data.login.statusCode === 200) {
      localStorage.setItem("userName", userName);
      localStorage.setItem("token", data.login.token);
      history.push("/private/welcome");
    }
  }, [data]);

  const regCompletedCallBack = async function (uName, password) {
    setLoginFLow(true);
    setuserName(uName);
    // console.log('login completed');
    login({
      variables: {
        user: {
          userName: uName,
          password,
          productType: dssBranding.key.toUpperCase(),
        },
      },
    });
  };

  return (
    <>
      <Header />
      <Grid container spacing={1} style={{ padding: "10px" }}>
        <Grid item xs={1} md={4} />
        <Grid item xs={10} md={4}>
          <>
            {dssBranding.regFlow === 2 ? (
              <UHRegistration
                {...props}
                registrationCompleted={regCompletedCallBack}
              />
            ) : (
              <MultiStepRegistration
                {...props}
                registrationCompleted={regCompletedCallBack}
              />
            )}
            <Dialog
              onClose={handleDialogClose}
              aria-labelledby="simple-dialog-title"
              open={loginFlow}
            >
              <DialogTitle id="simple-dialog-title">Logging in</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {loading && "Sucessfully registered. Logging you in"}
                  {data
                    ? data.login.statusCode == 200
                      ? "Sucessfully registered. Redirecting to home"
                      : "Registration Completed sucessfully. Unexpected error while logging in"
                    : null}
                  {error &&
                    "Registration Completed sucessfully. Unexpected error while logging in"}
                </DialogContentText>
              </DialogContent>
            </Dialog>
          </>
        </Grid>
        <Grid item xs={1} md={4} />
      </Grid>
    </>
  );
}
