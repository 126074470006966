import React from "react";
import { BottomNavigation, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "fixed",
    bottom: 0,
    height: 75,
    zIndex: 1250,
    [theme.breakpoints.down("sm")]: {
      height: 90,
    },
  },
  leftSide: {
    textAlign: "start",
    [theme.breakpoints.up("md")]: {
      paddingLeft: "25px !important",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      paddingTop: "5px !important",
    },
  },
  rightSide: {
    textAlign: "end",
    [theme.breakpoints.up("md")]: {
      paddingRight: "25px !important",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      paddingTop: "5px !important",
    },
  },
}));

export default () => {
  const classes = useStyles();
  return (
    <BottomNavigation
      className={classes.root}
      style={{ backgroundColor: "#ebebeb" }}
    >
      <Grid
        container
        spacing={2}
        justify="space-between"
        style={{ paddingTop: 20 }}
      >
        <Grid item xs={12} md={6} className={classes.leftSide}>
          <a
            style={{
              textDecoration: "none",
              padding: 5,
              color: "black",
              textAlign: "center",
            }}
            href="https://go.armadahealth.com/member-portal-terms-and-conditions"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms & Conditions
          </a>
          <a
            style={{ textDecoration: "none", padding: 5, color: "black" }}
            href="https://go.armadahealth.com/privacy-notice"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
          <a
            style={{ textDecoration: "none", padding: 5, color: "black" }}
            href="https://go.armadahealth.com/hipaa-authorization"
            target="_blank"
            rel="noopener noreferrer"
          >
            HIPAA Authorization
          </a>
        </Grid>
        <Grid item xs={12} md={6} className={classes.rightSide}>
          <Typography variant="body2">
            &copy; {new Date().getFullYear()} ArmadaHealth. All rights reserved
          </Typography>
        </Grid>
      </Grid>
    </BottomNavigation>
  );
};
