import QCCLogo from "./staticFiles/QCC_Logo_Stacked.png";
import TDCLogo from "./staticFiles/TDC_AH_Gray_Sky.png";
import QCCHORLOGO from "./staticFiles/QCC_Logo_Horizontal.png";

const brandingData = {
  scc: {
    logo: QCCLogo,
    horizontalLogo: QCCHORLOGO,
    key: "scc",
    regFlow: 1,
    canAllowFamily: true,
    canEditProfile: true,
    acceptTermsPerCase: false,
    logoMaxHeight: 150,
    name: "QualityCare Connect",
    email: "support@armadahealth.com",
    phone: "888-302-5735",
    phoneWithoutSymbols: "8883025735",
    faqLink: "http://armadahealth.com/member-faqs",
  },
  uh: {
    logo: TDCLogo,
    horizontalLogo: TDCLogo,
    key: "uh",
    regFlow: 2,
    acceptTermsPerCase: true,
    canAllowFamily: false,
    canEditProfile: false,
    logoMaxHeight: 65,
    name: "ArmadaCare",
    email: "support@armadacare.com",
    phone: "855-943-4595",
    phoneWithoutSymbols: "8559434595",
    faqLink: "https://my.armadahealth.com/tdc/faqs",
  },
};

export default () => {
  let currentBranding;
  if (document.location.href.indexOf("uh") > 0) {
    currentBranding = "uh";
  } else {
    currentBranding = "scc";
  }
  return brandingData[currentBranding];
};
