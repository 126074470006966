import React from "react";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AddIcon from "@material-ui/icons/Add";
import RedoIcon from "@material-ui/icons/Redo";
import SupervisorAccountSharpIcon from "@material-ui/icons/SupervisorAccountSharp";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import PersonIcon from "@material-ui/icons/Person";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ContactsIcon from "@material-ui/icons/Contacts";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import Collapse from "@material-ui/core/Collapse";
import { NavLink as Link, useRouteMatch } from "react-router-dom";
import Header from "./Header";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  selectedLink: {
    color: theme.palette.secondary.main,
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

function UserListItem({ userObj }) {
  const classes = useStyles();
  return (
    <ListItem
      button
      key={userObj.userId.id}
      component={Link}
      activeClassName={classes.selectedLink}
      className={classes.nested}
      to={`/private/members/${userObj.userId.id}`}
    >
      <ListItemIcon>
        <PersonIcon />
      </ListItemIcon>
      <ListItemText primary={userObj.firstName} />
    </ListItem>
  );
}

function UserAddButton() {
  const classes = useStyles();
  return (
    <ListItem
      button
      component={Link}
      activeClassName={classes.selectedLink}
      className={classes.nested}
      to="/private/members/add"
    >
      <ListItemIcon>
        <AddIcon color="secondary" />
      </ListItemIcon>
      <ListItemText primary="Add Member" />
    </ListItem>
  );
}

function ResponsiveDrawer({
  window,
  usersDetails: userObj,
  canAddMember,
  showAccInfo,
}) {
  const classes = useStyles();
  const { dssBranding } = useTheme();
  const match = useRouteMatch("/private/members");
  const [subMenuOpen, setSubMenuOpen] = React.useState(!!match);
  const handleSubMenuClick = (event) => {
    setSubMenuOpen(!subMenuOpen);
    event.stopPropagation();
  };

  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const forceCloseDrawer = () => {
    setMobileOpen(false);
  };

  const drawer = (
    <div onClick={forceCloseDrawer}>
      <div className={classes.toolbar} />
      <Divider />
      <List>
        <ListItem
          button
          key="RequestDoctor"
          component={Link}
          activeClassName={classes.selectedLink}
          to="/private/home"
        >
          <ListItemIcon>
            <RedoIcon color="secondary" />
          </ListItemIcon>
          <ListItemText primary="Request Doctors" color="secondary" />
        </ListItem>
        {showAccInfo && (
          <ListItem
            button
            key="Account Information"
            component={Link}
            activeClassName={classes.selectedLink}
            to="/private/accountinfo"
          >
            <ListItemIcon>
              <InfoOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Account Information" />
          </ListItem>
        )}
        <ListItem button key="Members" onClick={handleSubMenuClick}>
          <ListItemIcon>
            <SupervisorAccountSharpIcon />
          </ListItemIcon>
          <ListItemText primary="Members" />
          {subMenuOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={subMenuOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {canAddMember && <UserAddButton />}
            {userObj && (
              <>
                <UserListItem userObj={userObj} />
                {userObj.familyMembers &&
                  userObj.familyMembers.map((usObj, index) => (
                    <UserListItem key={index} userObj={usObj} />
                  ))}
              </>
            )}
          </List>
        </Collapse>
        <Hidden mdUp>
          <ListItem
            button
            key="Contact us"
            component="a"
            href={`mailto:${dssBranding.email}`}
          >
            <ListItemIcon>
              <ContactsIcon />
            </ListItemIcon>
            <ListItemText primary="Contact Us" />
          </ListItem>
          <ListItem
            button
            key="FAQ"
            component="a"
            target="_blank"
            href={dssBranding.faqLink}
          >
            <ListItemIcon>
              <LiveHelpIcon />
            </ListItemIcon>
            <ListItemText primary="FAQ" />
          </ListItem>
          <ListItem
            button
            key="ResetPassword"
            component={Link}
            activeClassName={classes.selectedLink}
            to="/private/resetpassword"
          >
            <ListItemIcon>
              <RotateLeftIcon />
            </ListItemIcon>
            <ListItemText primary="Change Password" />
          </ListItem>
          <ListItem
            button
            key="Logout"
            component={Link}
            activeClassName={classes.selectedLink}
            to="/private/logout"
          >
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </Hidden>
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <Header handleDrawerToggle={handleDrawerToggle} />
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden mdUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </div>
  );
}

export default ResponsiveDrawer;
