import React, { useState, useEffect } from "react";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { useAxios } from "../../context/axiosAuthenticated";
import { useCurrentUser } from "../../context/CurrentUser";
import BuyUpScreen from "./BuyUpScreen";
import WelcomeScreen from "./Welcome";
import HomeScreen from "./HomeScreen";
import AccInfo from "./AccountInformation";
import Member from "./MemberDetail";
import ResetPassword from "./ResetPassword";
import Logout from "./Logout";
import Drawer from "./Components/Drawer";

import RaiseCase from "./RaiseCase";
import HowItWorks from "./HowItWorks";
import AddMember from "./AddMember";
import EditMember from "./EditMember";
import TNC from "../../Components/TNC/TNC";
import TrackNavigationActivity from "./Components/TrackNavigationActivity";

const drawerWidth = 240;

const getUser = gql`
  query getUser($user: userDetailsInput) {
    user(user: $user) {
      response
      statusCode
      users {
        memberStatus
        paymentStatus {
          isBuyUp
          paymentStatus
        }
        armadaPlan {
          id
          planName
          coverageStatus
          effectiveDate
          terminationDate
          expireStatus
        }
        userId {
          id
        }
        firstName
        lastName
        gender
        birthDate
        userName
        termsAndCondition {
          version
        }
        hipaaAuth {
          version
        }
        privacyNote {
          version
        }
        createdAt
        registrationCode
        preferredCommunicationType
        email {
          email
        }
        address {
          street
          city
          state
          country
          zipCode
        }
        phoneNumbers {
          number
          teleCommunicationType
        }
        parent {
          userId {
            id
          }
          firstName
          lastName
        }
        familyMembers {
          userId {
            id
          }
          firstName
          lastName
          gender
          birthDate
          userName
          createdAt
          preferredCommunicationType
          email {
            email
          }
          address {
            street
            city
            state
            country
            zipCode
          }
          phoneNumbers {
            number
            teleCommunicationType
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  sectionDesktop: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  sectionLoader: {
    position: "fixed",
    top: "50%",
    left: "50%",
  },
}));

export default ({ match: { url: matchingURL } }) => {
  const { dssBranding } = useTheme();
  const { pathname } = useLocation();
  const [userTransactions, setUserTransactions] = useState([]);
  const [shouldBuyUp, setShouldBuyUp] = useState(null);
  const { data: usersDetails } = useQuery(getUser);
  const [termsVersionInfo, setTermsVersionInfo] = useState(null);
  const [canAddMember, setCanAddMember] = useState(null);
  const userObj = usersDetails ? usersDetails.user.users[0] : undefined;
  const [buyUpData, setBuyUpData] = useState(null);
  const [, setError] = useState(null);
  const classes = useStyles();
  const { axiosInstance } = useAxios();
  const [shouldAddExtendedFamily, setShouldAddExtendedFamily] = useState(null);
  const { setCurrentUser, currentUser } = useCurrentUser();

  useEffect(() => {
    const fetchBuyup = async () => {
      let { data: buyUpResponse } = await axiosInstance.get(
        "/buyupapiauth/userBuyUpDetails"
      );
      setBuyUpData(buyUpResponse);
      if (buyUpResponse && buyUpResponse.currentCoverage) {
        const memberAddNotAllowed =
          !dssBranding.canAllowFamily ||
          buyUpResponse.neverallowAddFamily ||
          (buyUpResponse.currentCoverage !== "Family" &&
            buyUpResponse.currentCoverage !== "Extended Family");
        if (buyUpResponse.currentCoverage === "Extended Family")
          setShouldAddExtendedFamily(true);
        setCanAddMember(!memberAddNotAllowed);
        if (buyUpResponse.currentCoverage !== "None") {
          setShouldBuyUp(false);
        } else {
          setShouldBuyUp(true);
          setCurrentUser({ ...currentUser, shouldShowWelcome: true });
        }
      } else {
        setError("Unable to find current coverage");
      }
    };
    const fetchTransactions = async () => {
      let { data: trans } = await axiosInstance.get(
        "/buyupapiauth/getusertransactions"
      );
      setUserTransactions(trans);
    };
    fetchBuyup();
    fetchTransactions();
  }, []);

  useEffect(() => {
    if (userObj) {
      let { shouldShowWelcome } = currentUser;
      setCurrentUser({ ...userObj, shouldShowWelcome });
      let hipaaVersion =
        userObj && userObj.hipaaAuth ? userObj.hipaaAuth.version : undefined;
      let privacyVersion =
        userObj && userObj.privacyNote
          ? userObj.privacyNote.version
          : undefined;
      let termsVersion =
        userObj && userObj.termsAndCondition
          ? userObj.termsAndCondition.version
          : undefined;
      setTermsVersionInfo({ termsVersion, privacyVersion, hipaaVersion });
    }
  }, [userObj]);

  return (
    <>
      <TrackNavigationActivity />
      <Drawer
        usersDetails={userObj}
        canAddMember={canAddMember}
        showAccInfo={userTransactions.length > 0}
      />
      {termsVersionInfo && (
        <TNC
          show
          versionInfo={termsVersionInfo}
          memberName={`${userObj.firstName} ${userObj.lastName}`}
          dob={userObj.birthDate}
          parentFlow={userObj.parent && userObj.parent.userId}
          parentName={
            userObj.parent &&
            userObj.parent.userId &&
            `${userObj.parent.firstName} ${userObj.parent.lastName}`
          }
        />
      )}

      <div className={classes.sectionDesktop}>
        {shouldBuyUp === null || !userObj ? (
          <CircularProgress className={classes.sectionLoader} />
        ) : (
          <Switch>
            <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
            {
              <Route
                path={`${matchingURL}/buyup`}
                render={(routeProps) => {
                  return <BuyUpScreen {...routeProps} buyUpData={buyUpData} />;
                }}
              />
            }
            {!shouldBuyUp && (
              <Route
                path={`${matchingURL}/welcome`}
                render={(routeProps) => {
                  return <WelcomeScreen buyupObj={buyUpData} {...routeProps} />;
                }}
              />
            )}
            {!shouldBuyUp && (
              <Route
                path={`${matchingURL}/home`}
                render={(routeProps) => {
                  return <HomeScreen buyupObj={buyUpData} {...routeProps} />;
                }}
              />
            )}
            {!shouldBuyUp && userTransactions.length && (
              <Route
                path={`${matchingURL}/accountinfo`}
                render={(routeProps) => {
                  return <AccInfo {...routeProps} buyUpData={buyUpData} />;
                }}
              />
            )}
            {!shouldBuyUp && canAddMember && (
              <Route
                path={`${matchingURL}/members/add`}
                render={(routeProps) => {
                  return (
                    <AddMember
                      primaryUserObj={userObj}
                      shouldAddExtendedFamily={shouldAddExtendedFamily}
                      {...routeProps}
                    />
                  );
                }}
              />
            )}
            {!shouldBuyUp && (canAddMember || dssBranding.canEditProfile) && (
              <Route
                path={`${matchingURL}/members/:memberId/edit`}
                render={(routeProps) => {
                  let { memberId } = routeProps.match.params;
                  if (!canAddMember && userObj.userId.id !== memberId) {
                    return null;
                  }
                  let currentUserObj =
                    userObj.userId.id === memberId ? userObj : undefined;
                  currentUserObj =
                    currentUserObj ||
                    userObj.familyMembers.find(
                      (familyMember) => familyMember.userId.id === memberId
                    );
                  return (
                    <EditMember
                      userObj={currentUserObj}
                      isPrimaryMember={userObj.userId.id === memberId}
                      {...routeProps}
                    />
                  );
                }}
              />
            )}
            {!shouldBuyUp && (
              <Route
                path={`${matchingURL}/members/:memberId`}
                render={(routeProps) => {
                  let { memberId } = routeProps.match.params;
                  let currentUserObj =
                    userObj.userId.id === memberId ? userObj : undefined;
                  currentUserObj =
                    currentUserObj ||
                    userObj.familyMembers.find(
                      (familyMember) => familyMember.userId.id === memberId
                    );
                  return (
                    <Member
                      userObj={currentUserObj}
                      canEditProfile={
                        userObj.userId.id === memberId
                          ? dssBranding.canEditProfile
                          : canAddMember
                      }
                      {...routeProps}
                    />
                  );
                }}
              />
            )}
            {
              <Route
                path={`${matchingURL}/resetpassword`}
                render={(routeProps) => {
                  return (
                    <ResetPassword
                      userName={userObj.userName}
                      {...routeProps}
                    />
                  );
                }}
              />
            }
            {!shouldBuyUp && (
              <Route
                path={`${matchingURL}/raisecase/:type`}
                render={(routeProps) => {
                  return (
                    <RaiseCase
                      {...routeProps}
                      currentCoverage={buyUpData.currentCoverage}
                      canAddMember={canAddMember}
                    />
                  );
                }}
              />
            )}
            {!shouldBuyUp && (
              <Route
                path={`${matchingURL}/howitworks`}
                render={(routeProps) => {
                  return <HowItWorks {...routeProps} />;
                }}
              />
            )}
            {
              <Route
                path={`${matchingURL}/logout`}
                render={(routeProps) => {
                  return <Logout {...routeProps} />;
                }}
              />
            }
            <Redirect
              to={shouldBuyUp ? `${matchingURL}/buyup` : `${matchingURL}/home`}
            />
          </Switch>
        )}
      </div>
    </>
  );
};
