import React, { useMemo } from "react";
import Dialog from "@material-ui/core/Dialog";
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import gql from "graphql-tag";
import { format } from "date-fns";
import * as yup from "yup";
import { useMutation } from "@apollo/react-hooks";
import FormikCheckBox from "../FormikFields/CheckBox";
import FormikTextField from "../FormikFields/TextField";
import Button from "../MuiComponents/Button";
import { useCurrentUser } from "../../context/CurrentUser";
import useVisibility from "./useVisibility";

const updateVersion = gql`
  mutation updateVersion($version: VersionInput) {
    updateVersion(version: $version) {
      response
      statusCode
    }
  }
`;

const updateHipaaVersion = gql`
  mutation updateHipaaVersion($version: VersionInput) {
    updateHipaaVersion(version: $version) {
      response
      statusCode
    }
  }
`;

const updatePrivacyVersion = gql`
  mutation updatePrivacyVersion($version: VersionInput) {
    updatePrivacyVersion(version: $version) {
      response
      statusCode
    }
  }
`;

const getValidationSchema = (parentFlow) => {
  let returnObj = yup.object().shape({
    legalRep: yup.bool(),
    name: yup
      .string()
      .required("Please enter name")
      .min(3, "Enter atleast 3 characters"),
    relationship: yup.string().when("legalRep", {
      is: true,
      then: yup
        .string()
        .required("Enter Relationship")
        .min(3, "Enter Relationship"),
      otherwise: yup.string().min(0),
    }),
  });
  if (parentFlow) {
    returnObj = returnObj.shape({
      parentName: yup.string().when("legalRep", {
        is: false,
        then: yup
          .string()
          .required("Please enter name")
          .min(3, "Enter atleast 3 characters"),
        otherwise: yup.string().min(0),
      }),
    });
  }
  return returnObj;
};

export default function ScrollDialog({
  open,
  bodyContent,
  onAccept,
  onClose,
  parentFlow,
  allowDismiss,
  type,
  version,
  updateToServer,
  parentName,
  memberName,
}) {
  const { currentUser } = useCurrentUser();
  const [onceVisible, currentElement, elementToTrack] = useVisibility();
  const validationSchema = useMemo(() => getValidationSchema(parentFlow), [
    parentFlow,
  ]);
  const mutationToUse =
    type === "tnc"
      ? updateVersion
      : type === "hipaa"
        ? updateHipaaVersion
        : updatePrivacyVersion;
  const [saveVersion] = useMutation(mutationToUse);

  const handleClose = () => {
    if (allowDismiss) {
      onClose();
    }
  };

  const handleSubmit = async (values) => {
    if (updateToServer) {
      await saveVersion({
        variables: {
          version: {
            version,
            parentName: values.parentName,
            memberName: values.name,
            legalRepresentative: values.legalRep,
            relationShip: values.relationship,
            userName: currentUser.userName,
            userId: { id: currentUser.userId.id },
          },
        },
      });
    }
    onAccept({
      type,
      body: {
        memberName: values.name,
        parentName: values.legalRep ? undefined : values.parentName,
        legalRepresentative: values.legalRep,
        relationship: values.legalRep ? values.relationship : undefined,
        version,
      },
    });
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          legalRep: false,
          name: memberName,
          relationship: "",
          parentName,
          date: format(new Date(), "yyyy-MM-dd"),
        }}
        validationSchema={validationSchema}
        validateOnMount
        validateOnBlur
        onSubmit={handleSubmit}
      >
        {(props) => (
          <Dialog
            open={open}
            scroll="paper"
            onClose={handleClose}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            {updateToServer && (
              <DialogTitle>
                {type === "tnc" && "We have updated our terms and condition"}
                {type === "hipaa" && "We have updated our hipaa authorization"}
                {type === "privacy" && "We have updated our privacy policy"}
              </DialogTitle>
            )}

            <DialogContent ref={elementToTrack} dividers>
              <DialogContentText
                id="scroll-dialog-description"
                ref={descriptionElementRef}
                tabIndex={-1}
                dangerouslySetInnerHTML={{ __html: bodyContent }}
              />

              <DialogContentText>
                <div ref={currentElement}>
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Field
                          component={FormikCheckBox}
                          name="legalRep"
                          label="I am a Legal Representative"
                        />
                      </Grid>
                      {props.values.legalRep && (
                        <Grid item xs={12}>
                          <Field
                            component={FormikTextField}
                            name="relationship"
                            label="Relationship"
                          />
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <Field
                          component={FormikTextField}
                          name="name"
                          label="Name"
                        />
                      </Grid>
                      {parentFlow && !props.values.legalRep && (
                        <Grid item xs={12}>
                          <Field
                            component={FormikTextField}
                            name="parentName"
                            label="Parent Name"
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Form>
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              {!onceVisible && (
                <Typography variant="body2">
                  Scroll to the bottom to Accept
                </Typography>
              )}
              <Button
                disabled={!props.isValid || !onceVisible}
                onClick={props.submitForm}
              >
                Accept
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Formik>
    </div>
  );
}
