import React from "react";
import {
  Paper,
  Grid,
  Typography,
  Radio,
  IconButton,
  Tooltip,
  FormControlLabel,
} from "@material-ui/core";
import { Field, Formik } from "formik";
import EditIcon from "@material-ui/icons/Edit";
import { Link } from "react-router-dom";
import FormikPhoneField from "../../Components/FormikFields/PhoneField";
import FormikTextField from "../../Components/FormikFields/TextField";
import FormikRadioGroup from "../../Components/FormikFields/RadioGroup";
import FormikDateField from "../../Components/FormikFields/DateField";
import FormikSelect from "../../Components/FormikFields/SelectField";
import states from "../../constants/constants";

const stateOptions = states.map(function (item) {
  return { value: item, label: item };
});

export default ({ userObj, canEditProfile, location }) => {
  const {
    firstName,
    lastName,
    gender,
    birthDate,
    email: { email },
    address: { city, state, street, zipCode },
  } = userObj;
  const number =
    userObj.phoneNumbers && userObj.phoneNumbers.length
      ? userObj.phoneNumbers[0].number
      : undefined;
  const values = {
    firstName,
    lastName,
    gender,
    birthDate: new Date(
      new Date(birthDate).getUTCFullYear(),
      new Date(birthDate).getUTCMonth(),
      new Date(birthDate).getUTCDate()
    ),
    email,
    city,
    state,
    street,
    zipCode,
    number,
  };
  // console.log(values)

  return !userObj ? (
    "Unable to get details of member"
  ) : (
    <Grid container style={{ padding: "10px" }}>
      <Grid item xs={1} md={3} />
      <Grid item xs={10} md={6}>
        <Paper
          style={{ display: "flex", padding: "20px", position: "relative" }}
          elevation={6}
        >
          {canEditProfile && (
            <Tooltip title="Click to Edit">
              <IconButton
                color="secondary"
                style={{ position: "absolute", top: "10px", right: "10px" }}
                component={Link}
                to={`${location.pathname}/edit`}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
          <Formik initialValues={values} validateOnBlur enableReinitialize>
            {() => (
              <Grid container spacing={6} direction="column" justify="center">
                <Grid item>
                  <Grid container spacing={3} justify="center">
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                      <Typography variant="h4">PROFILE</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container spacing={3} justify="center">
                    <Grid item xs={12} md={6}>
                      <Field
                        component={FormikTextField}
                        name="firstName"
                        label="first name"
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field
                        component={FormikTextField}
                        name="lastName"
                        label="last name"
                        fullWidth
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container spacing={3} justify="center">
                    <Grid item xs={12} md={6}>
                      <Field
                        component={FormikDateField}
                        name="birthDate"
                        disabled
                        textFieldProps={{
                          label: "Date of Birth",
                          fullWidth: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field component={FormikRadioGroup} name="gender" row>
                        <FormControlLabel
                          value="Male"
                          control={<Radio color="primary" />}
                          label="Male"
                          disabled
                        />
                        <FormControlLabel
                          value="Female"
                          control={<Radio color="primary" />}
                          label="Female"
                          disabled
                        />
                      </Field>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container spacing={3} justify="center">
                    <Grid item xs={12}>
                      <Field
                        component={FormikTextField}
                        name="email"
                        label="Email"
                        fullWidth
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container spacing={3} justify="center">
                    <Grid item xs={12}>
                      <Field
                        component={FormikTextField}
                        name="street"
                        label="Street Address"
                        fullWidth
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container spacing={3} justify="center">
                    <Grid item xs={12} md={6}>
                      <Field
                        component={FormikTextField}
                        name="city"
                        label="City"
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field
                        component={FormikSelect}
                        options={stateOptions}
                        name="state"
                        placeholder="State"
                        style={{ width: "82%" }}
                        isDisabled
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container spacing={3} justify="center">
                    <Grid item xs={12} md={6}>
                      <Field
                        component={FormikTextField}
                        name="zipCode"
                        label="Zip code"
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field
                        component={FormikPhoneField}
                        name="number"
                        label="Phone Number"
                        fullWidth
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item />
              </Grid>
            )}
          </Formik>
        </Paper>
      </Grid>
      <Grid item xs={1} md={3} />
    </Grid>
  );
};
