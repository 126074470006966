import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FormHelperText } from "@material-ui/core";
import { getIn } from "formik";
import InputAdornment from "@material-ui/core/InputAdornment";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import TextField from "../MuiComponents/TextField";

function CustomTextField(props) {
  const { onClick, value, textFieldProps, ...other } = props;
  return (
    <TextField
      onClick={onClick}
      value={value}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <CalendarTodayIcon />
          </InputAdornment>
        ),
      }}
      {...textFieldProps}
      {...other}
    />
  );
}

export default function ({
  maxDate,
  minDate,
  field,
  form,
  value,
  onChange,
  onBlur,
  helperText,
  textFieldProps,
  displayErrorMessage = true,
  locale,
  openToDate,
  ...other
}) {
  const changeHandler = (date) => {
    form.setFieldValue(field.name, date);
    setTimeout(100, () => {
      form.setFieldTouched(field.name);
    });
    if (onChange) {
      onChange(date);
    }
  };

  const blurHandler = () => {
    setTimeout(100, () => {
      form.setFieldTouched(field.name);
    });
  };
  const touch = getIn(form.touched, field.name);
  const errorText = getIn(form.errors, field.name);

  return (
    <>
      <DatePicker
        customInput={
          <CustomTextField
            textFieldProps={textFieldProps}
            error={!!(touch && errorText)}
          />
        }
        selected={field.value}
        openToDate={field.value ? undefined : openToDate}
        onChange={changeHandler}
        onBlur={blurHandler}
        {...other}
        maxDate={maxDate}
        minDate={minDate}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        popperClassName="Date-picker-popper"
        popperModifiers={{
          preventOverflow: {
            enabled: true,
            escapeWithReference: false,
            boundariesElement: "viewport",
          },
        }}
      />
      {displayErrorMessage ? (
        <FormHelperText error={Boolean(touch && errorText)}>
          {(touch && errorText) || helperText}
        </FormHelperText>
      ) : (
        helperText && <FormHelperText>{helperText}</FormHelperText>
      )}
    </>
  );
}
