import React from "react";
import { FormHelperText } from "@material-ui/core";
import { getIn } from "formik";
import AutoTab from "../MuiComponents/AutoTab";

export default ({
  field,
  form,
  helperText,
  onChange,
  displayErrorMessage = true,
}) => {
  let internalHelperText = helperText || "   ";
  const changeHandler = (value) => {
    form.setFieldValue(field.name, value);
    if (onChange) {
      onChange(value);
    }
  };
  const touch = getIn(form.touched, field.name);
  const errorText = getIn(form.errors, field.name);

  return (
    <>
      <AutoTab
        value={field.value || ""}
        onChange={changeHandler}
        error={!!(touch && errorText)}
      />
      {displayErrorMessage ? (
        <FormHelperText error={Boolean(touch && errorText)}>
          {(touch && errorText) || internalHelperText}
        </FormHelperText>
      ) : (
        internalHelperText && (
          <FormHelperText>{internalHelperText}</FormHelperText>
        )
      )}
    </>
  );
};
