import React, { useState, useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { useAxios } from "../../context/axiosAuthenticated";

const useStyles = makeStyles((theme) => ({
  caseFrame: {
    width: "100%",
    border: 0,
    height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
  },
}));

export default ({
  history,
  match: {
    params: { type },
  },
  currentCoverage,
  canAddMember,
}) => {
  const [caseTokenData, setCaseTokenData] = useState(null);
  const { axiosInstance } = useAxios();
  const classes = useStyles();
  useEffect(() => {
    const getCaseToken = async () => {
      let body = { casetype: type, currentCoverage };
      let { data: caseTokenReponse } = await axiosInstance.post(
        "/case/getCaseRedirectURL",
        body
      );
      setCaseTokenData(caseTokenReponse);
      // console.log(caseTokenReponse);
    };
    getCaseToken();
  }, []);
  useEffect(() => {
    const listenEvent = (event) => {
      if (event && event.data && event.data === "close") {
        history.push(`./`);
      }
    };
    if (window.addEventListener) {
      window.addEventListener("message", listenEvent, false);
    }
    return () => {
      if (window.removeEventListener)
        window.removeEventListener("message", listenEvent);
    };
  }, []);

  return !caseTokenData ? (
    <CircularProgress />
  ) : (
    <iframe
      title="Case Form"
      className={classes.caseFrame}
      src={
        canAddMember
          ? `${caseTokenData.caseUrl}&covType=${currentCoverage}`
          : caseTokenData.caseUrl
      }
    />
  );
};
