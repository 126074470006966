import React, { useEffect } from "react";
import {
  Paper,
  Grid,
  Typography,
  Radio,
  FormControlLabel,
} from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { Link, useHistory } from "react-router-dom";
import * as yup from "yup";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { format } from "date-fns";
import FormikPhoneField from "../../Components/FormikFields/PhoneField";
import FormikTextField from "../../Components/FormikFields/TextField";
import FormikRadioGroup from "../../Components/FormikFields/RadioGroup";
import FormikDateField from "../../Components/FormikFields/DateField";
import FormikSelect from "../../Components/FormikFields/SelectField";
import states from "../../constants/constants";
import Button from "../../Components/MuiComponents/Button";

const stateOptions = states.map((item) => {
  return { value: item, label: item };
});

const relationOptions = [
  { value: "Child", label: "Child" },
  { value: "Spouse", label: "Spouse" },
  { value: "Parent", label: "Parent" },
  { value: "AdultChild", label: "Adult Child" },
];

const addFamilyMember = gql`
  mutation addFamilyDetails($user: UserInput) {
    createFamilyMember(user: $user) {
      response
      statusCode
      userId
    }
  }
`;

let userSchema = yup.object().shape({
  firstName: yup
    .string()
    .required("Enter First Name")
    .max(50, "Too big"),
  lastName: yup
    .string()
    .required("Enter Last Name")
    .max(50, "Too big"),
  birthDate: yup
    .date()
    .typeError("Invalid Value")
    .required("DOB is required")
    .max(new Date(), `No future dates`),
  relation: yup.string().required("Select relationship"),
  gender: yup.string().required("Select Gender"),
  email: yup
    .string()
    .required("Enter Username")
    .max(100, "too big")
    .email("Invalid email"),
  street: yup
    .string()
    .required("Enter address")
    .max(100, "Too big")
    .min(3, "Should be minimum 3 characters"),
  city: yup
    .string()
    .required("Enter city")
    .max(50, "Too big")
    .min(3, "Should be minimum 3 characters"),
  state: yup.string().required("Select state"),
  zipCode: yup
  .string("Enter 5 digit code")
  .typeError("Invalid Value")
  .required("Enter 5 digit code")
  .matches("^[0-9]{5}$", "Enter 5 digit code")
  .notOneOf(['00000'],"Enter Valid Code"),
  number: yup
    .string()
    .required("Enter Phone Number")
    .test("validPhone", "Invalid Phone", (currentValue) => {
      if (currentValue) {
        let specialStrip = currentValue.replace(/[^\d]/g, "");
        return specialStrip.length === 10;
      }
      return false;
    }),
});

export default ({ primaryUserObj, shouldAddExtendedFamily }) => {
  const [saveUser, { loading, data, error }] = useMutation(addFamilyMember);
  const history = useHistory();
  const filteredRelationOptions = shouldAddExtendedFamily
    ? relationOptions
    : relationOptions.filter(
        (item) => item.value !== "AdultChild" && item.value !== "Parent"
      );

  const {
    email: { email: userEmail },
    address: {
      city: userCity,
      state: userState,
      street: userStreet,
      zipCode: userZipCode,
    },
  } = primaryUserObj;
  const userNumber =
    primaryUserObj.phoneNumbers && primaryUserObj.phoneNumbers.length
      ? primaryUserObj.phoneNumbers[0].number
      : undefined;
  const values = {
    firstName: "",
    lastName: "",
    gender: "",
    birthDate: "",
    relation: "",
    email: userEmail,
    city: userCity,
    state: userState,
    street: userStreet,
    zipCode: userZipCode,
    number: userNumber,
  };

  const submitFunction = ({
    firstName,
    lastName,
    gender,
    birthDate,
    email,
    city,
    state,
    street,
    zipCode,
    number,
    relation,
  }) => {
    const user = {
      firstName,
      lastName,
      birthDate: format(birthDate, "yyyy-MM-dd"),
      gender,
      email: {
        email,
      },
      address: {
        street,
        state,
        zipCode,
        city,
      },
      phoneNumbers: [
        {
          number,
          teleCommunicationType: "Mobile",
        },
      ],
      userId: { id: primaryUserObj.userId.id },
      preferredCommunicationType: "Call",
      userRoleType: relation,
    };
    saveUser({
      variables: {
        user,
      },
    });
  };
  useEffect(() => {
    if (
      data &&
      data.createFamilyMember &&
      data.createFamilyMember.statusCode === 200
    ) {
      history.push(`./${data.createFamilyMember.userId}`);
      history.go();
    }
  }, [data]);

  return !primaryUserObj ? (
    "Unable to get details of member"
  ) : (
    <Formik
      initialValues={values}
      validationSchema={userSchema}
      validateOnBlur
      validateOnChange={false}
      onSubmit={submitFunction}
    >
      {() => (
        <Grid container style={{ padding: "10px" }}>
          <Grid item xs={1} md={3} />
          <Grid item xs={10} md={6}>
            <Form>
              <Paper
                style={{
                  display: "flex",
                  padding: "20px",
                  position: "relative",
                }}
                elevation={6}
              >
                <Grid container spacing={6} direction="column" justify="center">
                  <Grid item>
                    <Grid container spacing={3} justify="center">
                      <Grid item xs={12} style={{ textAlign: "center" }}>
                        <Typography variant="h4">PROFILE</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container spacing={3} justify="center">
                      <Grid item xs={12} md={6}>
                        <Field
                          component={FormikTextField}
                          name="firstName"
                          label="first name"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          component={FormikTextField}
                          name="lastName"
                          label="last name"
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container spacing={3} justify="center">
                      <Grid item xs={12} md={6}>
                        <Field
                          component={FormikDateField}
                          name="birthDate"
                          openToDate={new Date("2000-01-01")}
                          maxDate={new Date()}
                          textFieldProps={{
                            label: "Date of Birth",
                            fullWidth: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field component={FormikRadioGroup} name="gender" row>
                          <FormControlLabel
                            value="Male"
                            control={<Radio color="primary" />}
                            label="Male"
                          />
                          <FormControlLabel
                            value="Female"
                            control={<Radio color="primary" />}
                            label="Female"
                          />
                        </Field>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container spacing={3} justify="center">
                      <Grid item xs={12} md={4}>
                        <Field
                          component={FormikSelect}
                          options={filteredRelationOptions}
                          name="relation"
                          placeholder="Relationship"
                          style={{ width: "82%" }}
                        />
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <Field
                          component={FormikTextField}
                          name="email"
                          label="Email"
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container spacing={3} justify="center">
                      <Grid item xs={12}>
                        <Field
                          component={FormikTextField}
                          name="street"
                          label="Address"
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container spacing={3} justify="center">
                      <Grid item xs={12} md={6}>
                        <Field
                          component={FormikTextField}
                          name="city"
                          label="City"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          component={FormikSelect}
                          options={stateOptions}
                          name="state"
                          placeholder="State"
                          style={{ width: "82%" }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container spacing={3} justify="center">
                      <Grid item xs={12} md={6}>
                        <Field
                          component={FormikTextField}
                          name="zipCode"
                          label="Zip code"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          component={FormikPhoneField}
                          name="number"
                          label="Phone Number"
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item style={{ textAlign: "center" }}>
                    <Button type="submit" loading={loading} disabled={loading}>
                      Save
                    </Button>
                    <Button
                      color="primary"
                      variant="text"
                      component={Link}
                      to="."
                    >
                      Cancel
                    </Button>
                    {error && (
                      <Typography variant="body1">
                        Unexpected Error. Please try again later.
                      </Typography>
                    )}
                    {loading && (
                      <Typography variant="body1">
                        Updating User Details
                      </Typography>
                    )}
                    {data && data.createFamilyMember.statusCode === 403 && (
                      <Typography variant="body1">
                        Email alraedy exists
                      </Typography>
                    )}
                    {data && data.createFamilyMember.statusCode === 500 && (
                      <Typography variant="body1">Update Failed</Typography>
                    )}
                    {data && data.createFamilyMember.statusCode === 200 && (
                      <Typography variant="body1">
                        Updated successfully
                      </Typography>
                    )}
                    {data &&
                      data.createFamilyMember.statusCode !== 200 &&
                      data.createFamilyMember.statusCode !== 500 &&
                      data.createFamilyMember.statusCode !== 403 && (
                        <Typography variant="body1">Error Occured</Typography>
                      )}
                  </Grid>
                  <Grid
                    item
                    style={{
                      margin: "0px 15px",
                      textAlign: "center",
                      fontWeight: 300,
                    }}
                  >
                    ArmadaHealth makes every effort to protect member
                    confidentiality. We do not have any financial relationship
                    with the physicians we recommend.
                  </Grid>
                </Grid>
              </Paper>
            </Form>
          </Grid>
          <Grid item xs={1} md={3} />
        </Grid>
      )}
    </Formik>
  );
};
