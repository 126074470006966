import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { useCurrentUser } from "../../../context/CurrentUser";

const addActivities = gql`
  mutation createActivity($activity: ActivityInput) {
    createActivity(activity: $activity) {
      response
      statusCode
    }
  }
`;

export default () => {
  const { pathname } = useLocation();
  const { currentUser } = useCurrentUser();
  const [prevLocation, setPrevLocation] = useState(pathname);
  const [saveActivity] = useMutation(addActivities);

  useEffect(() => {
    if (prevLocation !== pathname) {
      setPrevLocation(pathname);
      saveActivity({
        variables: {
          activity: {
            activityName: pathname,
            activityType: "Navigation",
            activityDescription: `from ${prevLocation} to ${pathname}`,
            rootId: 1,
            parentId: 1,
            createdBy:
              currentUser && currentUser.userName ? currentUser.userName : "",
            createdAt: new Date(),
            activityLogLevel: "INFO",
          },
        },
      });
    }
  });
  return null;
};
