import React from "react";
import { FormHelperText } from "@material-ui/core";
import { getIn } from "formik";
import TextField from "../MuiComponents/TextField";

export default ({
  field,
  form,
  name,
  helperText,
  error,
  onChange,
  onBlur,
  displayErrorMessage = true,
  textFieldComponent: Component = TextField,
  ...otherProps
}) => {
  let internalHelperText = helperText || "   ";
  const changeHandler = (event) => {
    form.setFieldValue(field.name, event.target.value);
    if (onChange) {
      onChange(event);
    }
  };
  const blurHandler = (event) => {
    form.setFieldTouched(field.name);
    if (onBlur) {
      onBlur(event);
    }
  };
  const touch = getIn(form.touched, field.name);
  const errorText = getIn(form.errors, field.name);

  return (
    <>
      <Component
        name={field.name}
        value={field.value || ""}
        onChange={changeHandler}
        onBlur={blurHandler}
        {...otherProps}
        error={!!(touch && errorText)}
      />
      {displayErrorMessage ? (
        <FormHelperText error={Boolean(touch && errorText)}>
          {(touch && errorText) || internalHelperText}
        </FormHelperText>
      ) : (
        internalHelperText && (
          <FormHelperText>{internalHelperText}</FormHelperText>
        )
      )}
    </>
  );
};
