import React, { useLayoutEffect } from "react";
import {
  Switch,
  Route,
  Redirect,
  HashRouter as Router,
  useLocation,
} from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Public from "./routes/public";
import Private from "./routes/private";
import getBrandingData from "./BrandingUtil";
import "./App.css";
import { CurrentUserProvider } from "./context/CurrentUser";
import Footer from "./Footer";
import { initializeGA } from './analytics';

function ScrollToTop() {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

initializeGA();

function App() {
  const font = "'Lato', sans-serif";
  const themeCalculator = () => {
    return createMuiTheme({
      palette: {
        primary: { main: "#39a7df" },
        secondary: { main: "#dc176c" },
      },
      dssBranding: getBrandingData(),
      footerHeight: 75,
      typography: {
        fontFamily: font,
      },
    });
  };
  return (
    <div className="App">
      <MuiThemeProvider theme={themeCalculator()}>
        <CssBaseline />
        <CurrentUserProvider>
          <Router basename={process.env.PUBLIC_URL}>
            <ScrollToTop />
            <Switch>
              <Route path="/private" component={Private} />
              <Route path="/" component={Public} />
              <Redirect to="/" />
            </Switch>
          </Router>
        </CurrentUserProvider>
        <Footer />
      </MuiThemeProvider>
    </div>
  );
}

export default App;
