import React, { useEffect } from "react";
import {
  Paper,
  Grid,
  Typography,
  Radio,
  FormControlLabel,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { Link, useHistory } from "react-router-dom";
import * as yup from "yup";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { format } from "date-fns";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "../../Components/MuiComponents/Button";
import states from "../../constants/constants";
import FormikSelect from "../../Components/FormikFields/SelectField";
import FormikDateField from "../../Components/FormikFields/DateField";
import FormikRadioGroup from "../../Components/FormikFields/RadioGroup";
import FormikTextField from "../../Components/FormikFields/TextField";
import FormikPhoneField from "../../Components/FormikFields/PhoneField";

const stateOptions = states.map(function (item) {
  return { value: item, label: item };
});

const deleteFamily = gql`
  mutation delete($user: UserIdentifierInput) {
    deleteFamilyMember(user: $user) {
      response
      statusCode
    }
  }
`;

const updateMember = gql`
  mutation updatePerson($user: UserInput) {
    updateUser(user: $user) {
      response
      statusCode
    }
  }
`;

let userSchema = yup.object().shape({
  firstName: yup
    .string()
    .required("Enter First Name")
    .max(50, "Too big"),
  lastName: yup
    .string()
    .required("Enter Last Name")
    .max(50, "Too big"),
  birthDate: yup
    .date()
    .typeError("Invalid Value")
    .required("DOB is required")
    .max(new Date(), "No future date"),
  gender: yup.string().required("Select Gender"),
  email: yup
    .string()
    .typeError("Invalid Value")
    .required("Enter Email")
    .max(100, "too big")
    .email("Invalid email"),
  street: yup
    .string()
    .required("Enter address")
    .max(100, "Too big")
    .min(3, "Should be minimum 3 characters"),
  city: yup
    .string()
    .required("Enter city")
    .max(50, "Too big")
    .min(3, "Should be minimum 3 characters"),
  state: yup.string().required("Select state"),
  zipCode: yup
  .string("Enter 5 digit code")
  .typeError("Invalid Value")
  .required("Enter 5 digit code")
  .matches("^[0-9]{5}$", "Enter 5 digit code")
  .notOneOf(['00000'],"Enter Valid Code"),
  number: yup
    .string()
    .required("Enter Phone Number")
    .test("validPhone", "Invalid Phone", (currentValue) => {
      if (currentValue) {
        let specialStrip = currentValue.replace(/[^\d]/g, "");
        return specialStrip.length === 10;
      }
      return false;
    }),
});

export default ({ userObj, isPrimaryMember }) => {
  const [saveUser, { loading, data, error }] = useMutation(updateMember);
  const [deleteUser, { loading: deleteLoading }] = useMutation(deleteFamily);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = React.useState(false);

  const history = useHistory();
  const {
    firstName,
    lastName,
    gender,
    birthDate,
    email: { email },
    address: { city, state, street, zipCode },
  } = userObj;
  const number =
    userObj.phoneNumbers && userObj.phoneNumbers.length
      ? userObj.phoneNumbers[0].number
      : undefined;
  const values = {
    firstName,
    lastName,
    gender,
    birthDate: new Date(
      new Date(birthDate).getUTCFullYear(),
      new Date(birthDate).getUTCMonth(),
      new Date(birthDate).getUTCDate()
    ),
    email,
    city,
    state,
    street,
    zipCode,
    number,
  };

  const handleConfirm = async () => {
    await deleteUser({ variables: { user: { id: userObj.userId.id } } });
    history.push("../");
    history.go();
  };
  const handleClose = () => {
    setConfirmDeleteOpen(false);
  };

  const deleteFamilyMember = () => {
    setConfirmDeleteOpen(true);
  };
  /* eslint-disable no-shadow */
  const submitFunction = ({
    firstName,
    lastName,
    gender,
    birthDate,
    email,
    city,
    state,
    street,
    zipCode,
    number,
  }) => {
    const user = {
      firstName,
      lastName,
      birthDate: format(birthDate, "yyyy-MM-dd"),
      gender,
      email: {
        email,
      },
      address: {
        street,
        state,
        zipCode,
        city,
      },
      phoneNumbers: [
        {
          number,
          teleCommunicationType: "Mobile",
        },
      ],
      userId: { id: userObj.userId.id },
      preferredCommunicationType: "Call",
    };
    saveUser({
      variables: {
        user,
      },
    });
  };
  /* eslint-enable no-shadow */
  useEffect(() => {
    if (data && data.updateUser && data.updateUser.statusCode === 200) {
      history.push("./");
      history.go();
    }
  }, [data]);

  return !userObj ? (
    "Unable to get details of member"
  ) : (
    <>
      <Formik
        initialValues={values}
        validationSchema={userSchema}
        validateOnBlur
        validateOnChange={false}
        onSubmit={submitFunction}
      >
        {() => (
          <Grid container style={{ padding: "10px" }}>
            <Grid item xs={1} md={3} />
            <Grid item xs={10} md={6}>
              <Form>
                <Paper
                  style={{
                    display: "flex",
                    padding: "20px",
                    position: "relative",
                  }}
                  elevation={6}
                >
                  {!isPrimaryMember && (
                    <Tooltip title="Click to Delete">
                      <IconButton
                        color="secondary"
                        style={{
                          position: "absolute",
                          top: "10px",
                          right: "10px",
                        }}
                        onClick={deleteFamilyMember}
                        disabled={deleteLoading}
                      >
                        <DeleteForeverIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Grid
                    container
                    spacing={6}
                    direction="column"
                    justify="center"
                  >
                    <Grid item>
                      <Grid container spacing={3} justify="center">
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                          <Typography variant="h4">PROFILE</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container spacing={3} justify="center">
                        <Grid item xs={12} md={6}>
                          <Field
                            component={FormikTextField}
                            name="firstName"
                            label="first name"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Field
                            component={FormikTextField}
                            name="lastName"
                            label="last name"
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container spacing={3} justify="center">
                        <Grid item xs={12} md={6}>
                          <Field
                            component={FormikDateField}
                            name="birthDate"
                            openToDate={new Date("2000-01-01")}
                            maxDate={new Date()}
                            textFieldProps={{
                              label: "Date of Birth",
                              fullWidth: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Field component={FormikRadioGroup} name="gender" row>
                            <FormControlLabel
                              value="Male"
                              control={<Radio color="primary" />}
                              label="Male"
                            />
                            <FormControlLabel
                              value="Female"
                              control={<Radio color="primary" />}
                              label="Female"
                            />
                          </Field>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container spacing={3} justify="center">
                        <Grid item xs={12}>
                          <Field
                            component={FormikTextField}
                            name="email"
                            label="Email"
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container spacing={3} justify="center">
                        <Grid item xs={12}>
                          <Field
                            component={FormikTextField}
                            name="street"
                            label="Street Address"
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container spacing={3} justify="center">
                        <Grid item xs={12} md={6}>
                          <Field
                            component={FormikTextField}
                            name="city"
                            label="City"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Field
                            component={FormikSelect}
                            options={stateOptions}
                            name="state"
                            placeholder="State"
                            style={{ width: "82%" }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container spacing={3} justify="center">
                        <Grid item xs={12} md={6}>
                          <Field
                            component={FormikTextField}
                            name="zipCode"
                            label="Zip code"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Field
                            component={FormikPhoneField}
                            name="number"
                            label="Phone Number"
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item style={{ textAlign: "center" }}>
                      <Button
                        type="submit"
                        loading={loading}
                        disabled={loading}
                      >
                        Save
                      </Button>
                      <Button
                        color="primary"
                        variant="text"
                        component={Link}
                        to="."
                      >
                        Cancel
                      </Button>
                      {error && (
                        <Typography variant="body1">
                          Unexpected Error. Please try again later.
                        </Typography>
                      )}
                      {loading && (
                        <Typography variant="body1">
                          Updating User Details
                        </Typography>
                      )}
                      {data && data.updateUser.statusCode === 403 && (
                        <Typography variant="body1">
                          Email alraedy exists
                        </Typography>
                      )}
                      {data && data.updateUser.statusCode === 500 && (
                        <Typography variant="body1">Update Failed</Typography>
                      )}
                      {data && data.updateUser.statusCode === 200 && (
                        <Typography variant="body1">
                          Updated successfully
                        </Typography>
                      )}
                      {data &&
                        data.updateUser.statusCode !== 200 &&
                        data.updateUser.statusCode !== 500 &&
                        data.updateUser.statusCode !== 403 && (
                          <Typography variant="body1">Error Occured</Typography>
                        )}
                    </Grid>
                    <Grid
                      item
                      style={{
                        margin: "0px 15px",
                        textAlign: "center",
                        fontWeight: 300,
                      }}
                    >
                      ArmadaHealth makes every effort to protect member
                      confidentiality. We do not have any financial relationship
                      with the physicians we recommend.
                    </Grid>
                  </Grid>
                </Paper>
              </Form>
            </Grid>
            <Grid item xs={1} md={3} />
          </Grid>
        )}
      </Formik>
      <Dialog open={confirmDeleteOpen} onClose={handleClose}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this member from your dependents
            list?
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center", flexWrap: "wrap" }}>
          <Button
            onClick={handleClose}
            variant="outlined"
            disabled={deleteLoading}
            color="primary"
          >
            No
          </Button>
          <Button
            onClick={handleConfirm}
            loading={deleteLoading}
            disabled={deleteLoading}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
