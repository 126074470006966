import React, { useEffect } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { createHttpLink } from "apollo-link-http";
import { ApolloProvider } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import fetch from "unfetch";
import { useTheme } from "@material-ui/core/styles";
import Registration from "./Registration";
import ForgetPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import Login from "./Login";
import { useHistory } from 'react-router-dom';
import { logPageView } from '../../analytics';

export default (props) => {
  const { footerHeight } = useTheme();

  const history = useHistory();
  useEffect(() => {
    logPageView(history);
  }, [history]);

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    connectToDevTools: true,
    link: createHttpLink({ uri: `/graphql`, fetch }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "no-cache",
        errorPolicy: "ignore",
      },
      query: {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
      },
    },
  });

  return (
    <ApolloProvider client={client}>
      {/* <Box
            display="flex"
            width={1} 
            height={1}
            bgcolor="white"
            alignItems="center"
            justifyContent="center"
        > */}
      <div style={{ marginBottom: footerHeight }}>
        <Switch>
          <Route path={`${props.match.url}login`} component={Login} />
          <Route
            path={`${props.match.url}forgotpassword`}
            component={ForgetPassword}
          />
          <Route
            path={`${props.match.url}reset-password/:token`}
            component={ResetPassword}
          />
          <Route
            path={`${props.match.url}enrollment`}
            component={Registration}
          />
          <Redirect to={{ ...props, pathname: `${props.match.url}login` }} />
        </Switch>
        {/* </Box> */}
      </div>
    </ApolloProvider>
  );
};
