import React, { useRef } from "react";
import { Grid, TextField } from "@material-ui/core";

const Input = React.forwardRef(
  ({ autoTab, onChange, value, onPaste, ...rest }, ref) => {
    return (
      <TextField
        value={value}
        inputProps={{
          type: "number",
          maxLength: 1,
          style: { textAlign: "center" },
        }}
        inputRef={ref}
        onKeyUp={autoTab}
        onChange={onChange}
        onPaste={onPaste}
        {...rest}
      />
    );
  }
);

const staticEmptyArray = ["", "", "", "", "", ""];

const getValueArray = (value) => {
  if (value && value.length <= 6) {
    let newArray = [...staticEmptyArray];
    newArray.splice(0, value.length, ...value);
    return newArray.map((item) => (item === "#" ? "" : item));
  }
  return [...staticEmptyArray];
};

const getReturnString = (value) => {
  let number = +value;
  if (!isNaN(number) && number > 100000 && number < 999999) {
    return number.toString();
  }
  return undefined;
};

export default ({ value, onChange }) => {
  const currValue = getValueArray(value);
  const [pasted, setPasted] = React.useState(false);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const [elRefs] = React.useState(() => {
    return [ref1, ref2, ref3, ref4, ref5, ref6];
  });

  const tabFunction = (tabindex) => (e) => {
    if (!pasted) {
      const BACKSPACE_KEY = 8;
      const DELETE_KEY = 46;
      const LEFT_KEY = 37;
      const RIGHT_KEY = 39;
      const TAB_KEY = 9;
      if (e.keyCode === TAB_KEY) {
        return;
      }
      let internalTabindex = Number(tabindex);
      let elem = 0;
      if (e.keyCode === BACKSPACE_KEY || e.keyCode === LEFT_KEY) {
        elem = internalTabindex > 0 && elRefs[internalTabindex - 1];
      } else if (e.keyCode !== DELETE_KEY || e.keyCode === RIGHT_KEY) {
        elem =
          internalTabindex < elRefs.length - 1 && elRefs[internalTabindex + 1];
      }
      if (elem) {
        elem.current.focus();
      }
    }
  };
  const changeFunc = (tabindex) => (e) => {
    if (!pasted) {
      let currentValueToReturn = [
        ...currValue.slice(0, tabindex),
        e.target.value,
        ...currValue.slice(tabindex + 1),
      ]
        .map((item) => (item === "" ? "#" : item))
        .join("");
      onChange(currentValueToReturn);
    }
  };

  const onPaste = (e) => {
    let pastedToken = getReturnString(e.clipboardData.getData("TEXT"));
    setPasted(true);
    if (pastedToken) {
      onChange(pastedToken);
      elRefs[5].current.focus();
    }
    setTimeout(() => setPasted(false), 500);
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <Grid container xs={12} spacing={1}>
      {elRefs.map((element, index) => {
        return (
          <Grid key={index} item xs={2}>
            <Input
              style={{ maxWidth: "100%" }}
              key={index}
              onPaste={onPaste}
              value={currValue[index]}
              onChange={changeFunc(index)}
              ref={element}
              autoTab={tabFunction(index)}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};
