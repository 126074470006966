import React, { useReducer, useEffect, useState } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import TNCPopUpDispalyer from "./PopUpDisplayer";

const getInitialState = ({ memberName = "", parentName = "" }) => {
  return {
    tnc: {
      isShown: false,
      memberName,
      version: "",
      parentName,
      legalRepresentative: false,
      relationship: "",
    },
    hipaa: {
      isShown: false,
      memberName,
      version: "",
      parentName,
      legalRepresentative: false,
      relationship: "",
    },
    privacy: {
      isShown: false,
      memberName,
      version: "",
      parentName,
      legalRepresentative: false,
      relationship: "",
    },
  };
};

function reducer(state, action) {
  switch (action.type) {
    case "tnc": {
      let tnc = { ...state.tnc, isShown: true, ...action.body };
      return {
        ...state,
        tnc: { ...tnc },
      };
    }
    case "hipaa": {
      let hipaa = { ...state.hipaa, isShown: true, ...action.body };
      return {
        ...state,
        hipaa: { ...hipaa },
      };
    }
    case "privacy": {
      let privacy = { ...state.privacy, isShown: true, ...action.body };
      return {
        ...state,
        privacy: { ...privacy },
      };
    }
    case "resetShown": {
      return {
        tnc: { ...state.tnc, isShown: false },
        hipaa: { ...state.hipaa, isShown: false },
        privacy: { ...state.privacy, isShown: false },
      };
    }
    case "updateInitState": {
      return getInitialState(action.body);
    }
    default:
      throw new Error("unsupported action");
  }
}

const getLatestTC = gql`
  query {
    termsAndCondition {
      termsAndCondition {
        id
        text
        version
        startDate
        endDate
      }
    }
    hipaaAuth {
      hipaaAuth {
        id
        text
        version
      }
    }
    privacyNote {
      privacyNote {
        id
        text
        version
      }
    }
  }
`;

export default ({
  parentFlow,
  onFinalAccept,
  onDismiss,
  show,
  memberName = "",
  parentName = "",
  dob = "",
  versionInfo,
}) => {
  const { data } = useQuery(getLatestTC);
  const history = useHistory();
  const [state, dispatch] = useReducer(
    reducer,
    { memberName, parentName },
    getInitialState
  );
  const [anythingToShow, setAnythingToShow] = useState(
    versionInfo ? null : true
  );

  useEffect(() => {
    if (
      state.privacy.isShown &&
      state.tnc.isShown &&
      state.hipaa.isShown &&
      show &&
      !versionInfo
    ) {
      dispatch({ type: "resetShown" });
      onFinalAccept(state);
    }
    if (
      state.privacy.isShown &&
      state.tnc.isShown &&
      state.hipaa.isShown &&
      show &&
      versionInfo &&
      anythingToShow
    ) {
      history.go();
    }
  });

  useEffect(() => {
    if (data && versionInfo) {
      if (
        data.termsAndCondition.termsAndCondition.version !==
        versionInfo.termsVersion
      ) {
        setAnythingToShow(true);
      } else {
        dispatch({ type: "tnc" });
      }
      if (data.hipaaAuth.hipaaAuth.version !== versionInfo.hipaaVersion) {
        setAnythingToShow(true);
      } else {
        dispatch({ type: "hipaa" });
      }
      if (data.privacyNote.privacyNote.version !== versionInfo.privacyVersion) {
        setAnythingToShow(true);
      } else {
        dispatch({ type: "privacy" });
      }
    }
  }, [data, versionInfo]);

  useEffect(() => {
    dispatch({ type: "updateInitState", body: { memberName, parentName } });
  }, [memberName, parentName]);

  return (
    <>
      {data && anythingToShow && !state.tnc.isShown && show && (
        <TNCPopUpDispalyer
          open={show}
          bodyContent={data.termsAndCondition.termsAndCondition.text}
          version={data.termsAndCondition.termsAndCondition.version}
          onAccept={dispatch}
          onClose={onDismiss}
          type="tnc"
          allowDismiss={!versionInfo}
          parentFlow={parentFlow}
          updateToServer={!!versionInfo}
          parentName={parentName}
          memberName={memberName}
        />
      )}
      {data &&
        anythingToShow &&
        state.tnc.isShown &&
        show &&
        !state.hipaa.isShown && (
          <TNCPopUpDispalyer
            open={show}
            bodyContent={data.hipaaAuth.hipaaAuth.text
              .replace("$var__-$firstname$var__-/$", memberName)
              .replace("$var__-$dob$var__-/$", dob)}
            version={data.hipaaAuth.hipaaAuth.version}
            onClose={onDismiss}
            onAccept={dispatch}
            type="hipaa"
            allowDismiss={!versionInfo}
            parentFlow={parentFlow}
            updateToServer={!!versionInfo}
            parentName={parentName}
            memberName={memberName}
          />
        )}
      {data &&
        anythingToShow &&
        state.tnc.isShown &&
        show &&
        state.hipaa.isShown &&
        !state.privacy.isShown && (
          <TNCPopUpDispalyer
            open={show}
            bodyContent={data.privacyNote.privacyNote.text}
            version={data.privacyNote.privacyNote.version}
            onClose={onDismiss}
            onAccept={dispatch}
            type="privacy"
            allowDismiss={!versionInfo}
            parentFlow={parentFlow}
            updateToServer={!!versionInfo}
            parentName={parentName}
            memberName={memberName}
          />
        )}
    </>
  );
};
