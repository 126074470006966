import React from "react";
import {
  AppBar,
  Grid,
  Toolbar,
  Menu,
  MenuItem,
  IconButton,
  Typography,
} from "@material-ui/core";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import MoreIcon from "@material-ui/icons/MoreVert";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  sectionDesktop: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  sectionMobile: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  logo: {
    [theme.breakpoints.down("sm")]: {
      height: "40px",
    },
    height: "50px",
    maxWidth: "100%",
  },
  rightNav: {
    [theme.breakpoints.up("md")]: {
      minWidth: 200,
    },
    textAlign: "end",
  },
}));

export default () => {
  const classes = useStyles();
  const history = useHistory();
  const { dssBranding } = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const menuId = "help-armada-menu";
  const mobileMenuId = "registration-responsive-menu";

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>
        <a
          style={{ textDecoration: "none" }}
          href={`mailto:${dssBranding.email}`}
        >
          Contact Us
        </a>
      </MenuItem>
      <MenuItem onClick={handleMenuClose}>
        <a
          style={{ textDecoration: "none" }}
          target="_blank"
          rel="noopener noreferrer"
          href={dssBranding.faqLink}
        >
          FAQs
        </a>
      </MenuItem>
    </Menu>
  );

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleMobileMenuClose}>
        <a
          style={{ textDecoration: "none" }}
          href={`mailto:${dssBranding.email}`}
        >
          Contact Us
        </a>
      </MenuItem>
      <MenuItem onClick={handleMobileMenuClose}>
        <a
          style={{ textDecoration: "none" }}
          target="_blank"
          rel="noopener noreferrer"
          href={dssBranding.faqLink}
        >
          FAQs
        </a>
      </MenuItem>
      <MenuItem onClick={handleMobileMenuClose}>
        <Typography variant="h6">
          <Link to="/">Login</Link>
        </Typography>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <AppBar position="fixed" color="inherit">
        {renderMenu}
        {renderMobileMenu}
        <Toolbar>
          <Grid container spacing={2} justify="space-between">
            <Grid item xs={10} md={8}>
              <img
                className={classes.logo}
                alt=""
                src={dssBranding.horizontalLogo}
                onClick={() => {
                  history.push("/");
                }}
              />
            </Grid>
            <Grid item xs={2} md={4} className={classes.rightNav}>
              <div className={classes.sectionMobile}>
                <IconButton
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <MoreIcon />
                </IconButton>
              </div>
              <div className={classes.sectionDesktop}>
                <IconButton
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  <Typography variant="h6">Help</Typography>
                  <ExpandMoreIcon />
                </IconButton>
                <IconButton aria-label="go to login" color="inherit">
                  <Typography variant="h6">
                    <Link to="/">Login</Link>
                  </Typography>
                </IconButton>
              </div>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  );
};
