import React from "react";
import { Paper, Grid, Typography } from "@material-ui/core";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import FormikTextField from "../../Components/FormikFields/TextField";
import Button from "../../Components/MuiComponents/Button";
import CustomPasswordField from "../../Components/MuiComponents/PasswordField";

const updatePassword = gql`
  mutation changePassword($user: userDetailsInput, $password: passwordInput) {
    changePassword(user: $user, password: $password) {
      response
      statusCode
    }
  }
`;

const verifyPasswordSchema = yup.object().shape({
  oldPassword: yup.string().required("Enter current password"),
  password: yup
    .string()
    .required("Enter New Password")
    .min(
      8,
      "Passwords must be at least 8 characters long and contain both numeric and alphabetic characters"
    )
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)[\040-\176]{8,}$/,
      "Passwords must be at least 8 characters long and contain both numeric and alphabetic characters"
    ),
  confirmPassword: yup
    .string()
    .required("Enter Password")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

export default ({ userName }) => {
  const [changePassword, { loading, data }] = useMutation(updatePassword);
  const submitFunction = async (values) => {
    const user = {
      userName,
    };
    const password = {
      oldPassword: values.oldPassword,
      newPassword: values.password,
    };
    await changePassword({
      variables: {
        user,
        password,
      },
    });
  };

  return (
    <Formik
      initialValues={{
        oldPassword: "",
        password: "",
        confirmPassword: "",
        tocAccept: false,
      }}
      validationSchema={verifyPasswordSchema}
      validateOnBlur
      validateOnChange={false}
      onSubmit={submitFunction}
    >
      {() => {
        return (
          <Form>
            <Grid container style={{ padding: "10px" }}>
              <Grid item xs={1} md={3} />
              <Grid item xs={10} md={6}>
                <Paper
                  style={{ display: "flex", padding: "20px" }}
                  elevation={6}
                >
                  <Grid
                    container
                    spacing={4}
                    direction="column"
                    justify="center"
                  >
                    <Grid item style={{ textAlign: "center" }}>
                      <Typography variant="h4">CHANGE PASSWORD</Typography>
                    </Grid>
                    <Grid item style={{ textAlign: "center" }}>
                      <Grid
                        container
                        justify="center"
                        style={{ padding: "10px" }}
                        spacing={4}
                      >
                        <Grid item xs={12}>
                          <Grid container>
                            <Grid item xs={1} md={3} />
                            <Grid item xs={10} md={6}>
                              <Field
                                component={FormikTextField}
                                type="password"
                                name="oldPassword"
                                label="Current Password"
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={1} md={3} />
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container>
                            <Grid item xs={1} md={3} />
                            <Grid item xs={10} md={6}>
                              <Field
                                component={FormikTextField}
                                type="password"
                                name="password"
                                label="New Password"
                                textFieldComponent={CustomPasswordField}
                                fullWidth
                              />
                              <Grid item xs={1} md={3} />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container>
                            <Grid item xs={1} md={3} />
                            <Grid item xs={10} md={6}>
                              <Field
                                component={FormikTextField}
                                type="password"
                                name="confirmPassword"
                                label="Confirm New Password"
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={1} md={3} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item style={{ textAlign: "center" }}>
                      <Button
                        type="submit"
                        loading={loading}
                        disabled={loading}
                      >
                        Submit
                      </Button>
                      {data && data.changePassword.statusCode === 200 && (
                        <Typography variant="body1">
                          Successfully updated the password
                        </Typography>
                      )}
                      {data && data.changePassword.statusCode !== 200 && (
                        <Typography variant="body1">
                          Unable to change password
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={1} md={3} />
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};
