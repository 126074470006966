import React, { useEffect, useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import { Paper, Grid, Typography, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Field, Form, Formik } from "formik";
import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/react-hooks";
import { Link, useHistory, useLocation } from "react-router-dom";
import * as yup from "yup";
import { logPageView } from '../../analytics';
import qs from "qs";
import Button from "../../Components/MuiComponents/Button";
import FormikTextField from "../../Components/FormikFields/TextField";

const loginSchema = yup.object().shape({
  userName: yup
    .string()
    .required("Enter Username")
    .max(50, "too big")
    .email("Invalid email"),
  password: yup.string().required("Enter Password"),
});

const loginQuery = gql`
  query login($user: LoginInput) {
    login(user: $user) {
      response
      statusCode
      token
    }
  }
`;

export default () => {
  const { dssBranding } = useTheme();
  const [loginfunc, { loading, data, error }] = useLazyQuery(loginQuery);
  const history = useHistory();
  const { search, state } = useLocation();
  const [showSessionTimeout, setShowSessionTimeout] = useState(!!state);
  const [formValues, setFormValues] = useState(null);

  useEffect(() => {
    if (data && data.login.statusCode === 200) {
      localStorage.setItem("userName", formValues.userName.toLowerCase());
      localStorage.setItem("token", data.login.token);
      // console.log(qs.parse(search), { ignoreQueryPrefix: true });
      const redirectTo =
        qs.parse(search, { ignoreQueryPrefix: true }).returnTo || "/private";
      history.push(redirectTo);
    }
  }, [data]);

  useEffect(() => {
    let timeoutInstance = setTimeout(() => {
      setShowSessionTimeout(false);
    }, 5000);
    return () => {
      clearTimeout(timeoutInstance);
    };
  }, []);

  useEffect(() => {
    logPageView(history);
  }, [history]);

  const submitFunction = async (values) => {
    setFormValues(values);
    loginfunc({
      variables: {
        user: {
          userName: values.userName,
          password: values.password,
          productType: dssBranding.key.toUpperCase(),
        },
      },
    });
  };
  return (
    <Grid container spacing={1}>
      <Grid item xs={1} md={4} />
      <Grid item xs={10} md={4} style={{ marginTop: "5%" }}>
        <Formik
          initialValues={{ userName: "", password: "" }}
          validationSchema={loginSchema}
          validateOnBlur
          validateOnChange={false}
          onSubmit={submitFunction}
        >
          {() => (
            <Form>
              <Snackbar
                open={showSessionTimeout}
                message="Session expired. Login to continue."
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
              />
              <Paper style={{ padding: "20px" }} elevation={6}>
                <Grid container spacing={2} direction="column" justify="center">
                  <Grid item style={{ textAlign: "center" }}>
                    <img
                      style={{
                        maxWidth: "80%",
                        height: "100%",
                        maxHeight: dssBranding.logoMaxHeight,
                      }}
                      src={dssBranding.logo}
                    />
                  </Grid>
                  <Grid item />
                  <Grid item>
                    <Grid container>
                      <Grid item xs={1} md={2} />
                      <Grid item xs={10} md={8}>
                        <Field
                          type="email"
                          fullWidth
                          component={FormikTextField}
                          name="userName"
                          label="Username"
                        />
                      </Grid>
                      <Grid item xs={1} md={2} />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container>
                      <Grid item xs={1} md={2} />
                      <Grid item xs={10} md={8}>
                        <Field
                          type="password"
                          component={FormikTextField}
                          name="password"
                          label="password"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={1} md={2} />
                    </Grid>
                  </Grid>
                  <Grid item>
                    {error && (
                      <Alert severity="Error">
                        Unexpected Error. Please try again later.
                      </Alert>
                    )}
                    {data ? (
                      data.login.statusCode == 200 ? (
                        <Typography variant="body1">
                          Login Successfull
                        </Typography>
                      ) : (
                          <Alert severity="Error">
                            {data.login.response
                              ? data.login.response
                              : "Email or password is invalid"}{" "}
                          </Alert>
                        )
                    ) : null}
                  </Grid>
                  <Grid item style={{ textAlign: "center" }}>
                    <Button type="submit" loading={loading} disabled={loading}>
                      Login
                    </Button>
                  </Grid>
                  <Grid item style={{ textAlign: "center" }}>
                    <Typography variant="h6">- OR -</Typography>
                  </Grid>
                  <Grid item style={{ textAlign: "center" }}>
                    <Button
                      color="primary"
                      variant="outlined"
                      component={Link}
                      to="./enrollment"
                    >
                      Create Account
                    </Button>
                  </Grid>
                  <Grid item style={{ textAlign: "center" }}>
                    <Link to="./forgotPassword">Forgot Password?</Link>
                  </Grid>
                  <Grid item style={{ textAlign: "center" }}>
                    <Typography variant="body1">{`Need help? Contact us at ${dssBranding.phone} or ${dssBranding.email}`}</Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Form>
          )}
        </Formik>
      </Grid>
      <Grid item xs={1} md={4} />
    </Grid>
  );
};
