import React from "react";
import {
  AppBar,
  Grid,
  Toolbar,
  Menu,
  MenuItem,
  IconButton,
  Typography,
} from "@material-ui/core";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useCurrentUser } from "../../../context/CurrentUser";

const useStyles = makeStyles((theme) => ({
  sectionDesktop: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  logo: {
    [theme.breakpoints.down("sm")]: {
      height: "40px",
    },
    height: "50px",
    maxWidth: "100%",
  },
  rightNav: {
    [theme.breakpoints.up("md")]: {
      minWidth: 200,
    },
    textAlign: "end",
  },
}));

export default ({ handleDrawerToggle }) => {
  const classes = useStyles();
  const { currentUser } = useCurrentUser();
  const history = useHistory();
  const { dssBranding } = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [profileAnchor, setProfileAnchor] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isLoginMenuOpen = Boolean(profileAnchor);

  const menuId = "help-armada-menu";
  const profileMenuId = "profile-menu";

  const handleHelpMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfileMenuOpen = (event) => {
    setProfileAnchor(event.currentTarget);
  };
  const handleProfileMenuClose = () => {
    setProfileAnchor(null);
  };

  const renderMenu = (
    <Menu
      id={menuId}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>
        <a
          style={{ textDecoration: "none", width: "100%" }}
          href={`mailto:${dssBranding.email}`}
        >
          Contact Us
        </a>
      </MenuItem>
      <MenuItem onClick={handleMenuClose}>
        <a
          style={{ textDecoration: "none", width: "100%" }}
          target="_blank"
          rel="noopener noreferrer"
          href={dssBranding.faqLink}
        >
          FAQs
        </a>
      </MenuItem>
    </Menu>
  );

  const profileMenu = (
    <Menu
      id={profileMenuId}
      anchorEl={profileAnchor}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isLoginMenuOpen}
      onClose={handleProfileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuClose}>
        <Link
          style={{ textDecoration: "none", width: "100%" }}
          to="/private/resetpassword"
        >
          Change Password
        </Link>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuClose}>
        <Link
          style={{ textDecoration: "none", width: "100%" }}
          to="/private/logout"
        >
          Logout
        </Link>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <AppBar position="fixed" color="inherit" style={{ zIndex: 1250 }}>
        {renderMenu}
        {profileMenu}
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Grid container spacing={2} justify="space-between">
            <Grid item xs={10} md={8}>
              <img
                className={classes.logo}
                alt=""
                src={dssBranding.horizontalLogo}
                onClick={() => {
                  history.push("/private");
                }}
              />
            </Grid>
            <Grid item xs={2} md={4} className={classes.rightNav}>
              <div className={classes.sectionDesktop}>
                <IconButton
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleHelpMenuOpen}
                  color="inherit"
                >
                  <Typography variant="h6">Help</Typography>
                  <ExpandMoreIcon fontSize="small" />
                </IconButton>
                <IconButton
                  aria-label="Profile"
                  color="inherit"
                  aria-controls={profileMenuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                >
                  <Typography variant="h6">
                    {currentUser.firstName ? currentUser.firstName : "Profile"}
                  </Typography>
                  <ExpandMoreIcon fontSize="small" />
                </IconButton>
              </div>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  );
};
