import React from "react";
import { Paper, Grid, Typography } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { Link } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import * as yup from "yup";
import Button from "../../Components/MuiComponents/Button";
import FormikTextField from "../../Components/FormikFields/TextField";

const resetPasswordScheme = yup.object().shape({
  password: yup
    .string()
    .required("Enter Password")
    .min(
      8,
      "Passwords must be at least 8 characters long and contain both numeric and alphabetic characters"
    )
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)[\040-\176]{8,}$/,
      "Passwords must be at least 8 characters long and contain both numeric and alphabetic characters"
    ),
  confirmPassword: yup
    .string()
    .required("Enter Password")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const resetPass = gql`
  mutation resetPassword($resetPassword: ResetPasswordInput) {
    resetPassword(resetPassword: $resetPassword) {
      response
      statusCode
    }
  }
`;

export default (props) => {
  console.log(props);
  const { dssBranding } = useTheme();
  const { token } = props.match.params;
  const [resetPasswordMutation, { loading, data, error }] = useMutation(
    resetPass
  );
  const submitFunction = async (values) => {
    resetPasswordMutation({
      variables: {
        resetPassword: {
          token,
          password: values.password,
        },
      },
    });
  };
  return (
    <Grid container spacing={1}>
      <Grid item xs={1} md={4} />
      <Grid item xs={10} md={4} style={{ marginTop: "5%" }}>
        <Formik
          initialValues={{ password: "", confirmPassword: "" }}
          validationSchema={resetPasswordScheme}
          validateOnBlur
          validateOnChange={false}
          onSubmit={submitFunction}
        >
          {() => (
            <Form>
              <Paper style={{ padding: "20px" }} elevation={6}>
                <Grid container spacing={2} direction="column" justify="center">
                  <Grid item style={{ textAlign: "center" }}>
                    <img
                      src={dssBranding.logo}
                      alt=""
                      style={{
                        maxWidth: "80%",
                        height: "100%",
                        maxHeight: dssBranding.logoMaxHeight,
                      }}
                    />
                  </Grid>
                  <Grid item style={{ textAlign: "center" }}>
                    <Typography variant="h4">Reset Password</Typography>
                  </Grid>
                  <Grid item>
                    <Grid container>
                      <Grid item xs={1} md={2} />
                      <Grid item xs={10} md={8}>
                        <Field
                          type="password"
                          component={FormikTextField}
                          name="password"
                          label="New Password"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={1} md={2} />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container>
                      <Grid item xs={1} md={2} />
                      <Grid item xs={10} md={8}>
                        <Field
                          type="password"
                          component={FormikTextField}
                          name="confirmPassword"
                          label="Confirm Password"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={1} md={2} />
                    </Grid>
                  </Grid>
                  <Grid item style={{ textAlign: "center" }}>
                    <Button type="submit" loading={loading} disabled={loading}>
                      Reset Password
                    </Button>
                    {error && (
                      <Typography variant="body1">
                        Unexpected Error. Please try again later.
                      </Typography>
                    )}
                    {data &&
                      data.resetPassword &&
                      data.resetPassword.statusCode === 200 && (
                        <Typography variant="body1">
                          Password Reset Successfully.
                          <Link to="./">Click Here</Link> to login.
                        </Typography>
                      )}
                    {data &&
                      !(
                        data.resetPassword &&
                        data.resetPassword.statusCode === 200
                      ) && (
                        <Typography variant="body1">
                          Link expired. You can trigger forgot password again.
                          <Link to="./">Click Here</Link> to login.
                        </Typography>
                      )}
                  </Grid>
                  <Grid item style={{ textAlign: "center" }}>
                    <Typography variant="body1">{`Need help? Contact us at ${dssBranding.phone} or ${dssBranding.email}`}</Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Form>
          )}
        </Formik>
      </Grid>
      <Grid item xs={1} md={4} />
    </Grid>
  );
};
