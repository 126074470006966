import React from "react";
import { Paper, Grid, Typography } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/react-hooks";
import { Link } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import * as yup from "yup";
import Button from "../../Components/MuiComponents/Button";
import FormikTextField from "../../Components/FormikFields/TextField";
import CaptchaField from "../../Components/FormikFields/CaptchaField";
const forgotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .required("Enter Username")
    .max(50, "too big")
    .email("Invalid email"),
  captcha: yup.string().required("Required")
});

const forgotPassword = gql`
  query forgot($user: userDetailsInput) {
    forgotPasswordMail(user: $user) {
      response
      statusCode
    }
  }
`;

export default () => {
  const { dssBranding } = useTheme();
  const recaptchaRef = React.createRef();

  const [forgotPasswordQuery, { loading, data, error }] = useLazyQuery(
    forgotPassword
  );
  const submitFunction = async (values, { resetForm }) => {
    forgotPasswordQuery({
      variables: {
        user: {
          userName: values.email,
          plan: dssBranding.key,
          captchaCode: values.captcha
        },
      },
    });
    resetForm();
    recaptchaRef.current.reset();
  };
  return (
    <Grid container spacing={1}>
      <Grid item xs={1} md={4} />
      <Grid item xs={10} md={4} style={{ marginTop: "5%" }}>
        <Formik
          initialValues={{ email: "", captcha: "" }}
          validationSchema={forgotPasswordSchema}
          validateOnBlur
          validateOnChange={false}
          onSubmit={submitFunction}
        >
          {() => (
            <Form>
              <Paper style={{ padding: "20px" }} elevation={6}>
                <Grid container spacing={2} direction="column" justify="center">
                  <Grid item style={{ textAlign: "center" }}>
                    <img
                      src={dssBranding.logo}
                      alt=""
                      style={{
                        maxWidth: "80%",
                        height: "100%",
                        maxHeight: dssBranding.logoMaxHeight,
                      }}
                    />
                  </Grid>
                  <Grid item style={{ textAlign: "center" , marginTop: "5%" }}>
                    <Typography variant="h4">{data? "Email Sent" : "Forgot Password?"}</Typography>
                  </Grid>
                  {!data && (
                    <>
                    <Grid item>
                    <Grid container>
                      <Grid item xs={1} md={2} />
                      <Grid item xs={10} md={8}>
                        <Field
                          type="email"
                          component={FormikTextField}
                          name="email"
                          label="email / username"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={1} md={2} />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container>
                      <Grid item xs={1} md={2} />
                      <Grid item xs={10} md={8}>
                        <Field
                        component={CaptchaField}
                          name="captcha"
                          sitekey="6LePkQgfAAAAAJJM3bZI7mQ6qdjJ3pzUbdvfHKmD"
                          innerRef={recaptchaRef}
                        />
                      </Grid>
                      <Grid item xs={1} md={2} />
                    </Grid>
                  </Grid>
                  <Grid item style={{ textAlign: "center" }}>
                    <Button type="submit" loading={loading} disabled={loading}>
                      Send Email
                    </Button>
                    {error && (
                      <Typography variant="body1">
                        Unexpected Error. Please try again later.
                      </Typography>
                    )}
                    
                  </Grid>
                  </>
                  )}
                  <Grid item style={{ textAlign: "center" }}>
                    {data && (
                      <Typography variant="body1">
                        Check your Inbox to re-activate your account.{" "}
                        <Link to="./">Click Here</Link> to login.
                      </Typography>
                    )}
                    <Typography variant="body1">{`Need help? Contact us at ${dssBranding.phone} or ${dssBranding.email}`}</Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Form>
          )}
        </Formik>
      </Grid>
      <Grid item xs={1} md={4} />
    </Grid>
  );
};
