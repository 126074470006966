import React from "react";
import { Paper, Grid, Typography, Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Button from "../../Components/MuiComponents/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    paddingTop: theme.spacing(10),
  },
}));

export default () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Grid container style={{ padding: "10px" }}>
        <Grid item xs={1} md={3} />
        <Grid item xs={10} md={6}>
          <Paper style={{ display: "flex", padding: "20px" }} elevation={6}>
            <Grid container spacing={4} direction="column" justify="center">
              <Grid item style={{ textAlign: "center" }}>
                <Typography variant="h4">THANK YOU</Typography>
              </Grid>
              <Grid item style={{ textAlign: "center" }}>
                <Typography variant="body1">
                  Your account has been created. If you need a physician now,
                  you can request one on the next screen. Otherwise we&apos;ll
                  be here when you need us.
                </Typography>
              </Grid>
              <Grid item style={{ textAlign: "center" }}>
                <Button style={{ margin: "5px" }} component={Link} to="./home">
                  Next
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={1} md={3} />
      </Grid>
    </Box>
  );
};
