import React, { useState, useEffect } from "react";
import { FormHelperText, useTheme, useMediaQuery } from "@material-ui/core";
import { getIn } from "formik";
import Recaptcha from "react-google-recaptcha";

export default ({
  field,
  form,
  name,
  helperText,
  error,
  onChange,
  displayErrorMessage = true,
  innerRef,
  size,
  ...otherProps
}) => {
  let internalHelperText = helperText || "   ";
  const changeHandler = (value) => {
    form.setFieldValue(field.name, value ? value : "");
    if (onChange) {
      onChange(value);
    }
  };
  const touch = getIn(form.touched, field.name);
  const errorText = getIn(form.errors, field.name);
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const [reloadOnce, setReloadOnce] = useState(false);
  useEffect(() => {
    setTimeout(() => { if (!reloadOnce) setReloadOnce(true) }, 500);
  });

  return (

    <>
      {reloadOnce &&
        <Recaptcha
          name={field.name}
          onChange={changeHandler}
          ref={innerRef}
          size={smallScreen ? "compact" : "normal"}
          {...otherProps}
        />
      }
      {displayErrorMessage ? (
        <FormHelperText error={Boolean(touch && errorText)}>
          {(touch && errorText) || internalHelperText}
        </FormHelperText>
      ) : (
        internalHelperText && (
          <FormHelperText>{internalHelperText}</FormHelperText>
        )
      )}
    </>
  );
};
